import React, { useEffect } from 'react'
import {
  IPropertyFinancials,
  PropertyFinancialsFields,
  propertyFinancialsInitialValues as initialValues,
} from '../core/_models'
import StandardForm from 'app/components/StandardForm'
import { addPropertyFinancialsSchema } from '../core/_schema'
import { useEditPropertyMutation, useGetPropertyByIdQuery } from '../core/api'
import { useNavigate, useParams } from 'react-router-dom'
import { getFields } from './fields'
import { toast } from 'react-toastify'

const AddFinancials: React.FC<{ values?: any }> = ({ values = {} }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    if (!id || typeof id === 'undefined') {
      navigate('/property/list', { replace: true })
    }
    // eslint-disable-next-line
  }, [])

  const { data: propertyValues } = useGetPropertyByIdQuery({ id: id! }, {
		selectFromResult: (result) => {
			const data = result.data?.data ?? {}
			let validEntries = Object.keys(data).reduce((obj: any, item: any) => {
				if (Object.keys(initialValues).includes(item)) {
					return { ...obj, [item]: parseInt(data[item]) || 0 }
				}
				return obj
			}, {})
			return {
				...result,
				data: validEntries
			}
		}
	})

  // const [addPropertyFinancials, addPropertyFinancialsResponse] = useAddPropertyFinancialsMutation()
  const [updateProperty, updatePropertyResponse] = useEditPropertyMutation()
  const handleSubmit = async (values: IPropertyFinancials, { setSubmitting, resetForm }: any) => {
    setSubmitting(true)
    const data = (Object.keys(values) as PropertyFinancialsFields[]).reduce(
      (obj: Partial<IPropertyFinancials>, v: PropertyFinancialsFields) => {
        if (!!values[v]) {
          obj[v] = parseInt(values[v].toString()) || 0
        }
        return obj
      },
      {}
    )
    const token = localStorage.getItem("auth_token") ?? ""
    await updateProperty({
      id: id!,
      data,
      token
    })
    setSubmitting(false)
    resetForm()
  }
  useEffect(() => {
    if (updatePropertyResponse.isSuccess) {
      toast.success("Property Financials has been changed successfully")
      navigate(`/property/${id}/financials`, { replace: true })
    }
    // eslint-disable-next-line
  }, [updatePropertyResponse.isSuccess])

  useEffect(() => {
    if (updatePropertyResponse.isError) {
      toast.error((updatePropertyResponse.error as any).data.errors[0].title)
    }
    // eslint-disable-next-line
  }, [updatePropertyResponse.isError])

  return (
    <StandardForm
      getFields={getFields}
      title='Property Financials'
      submitTitle='Submit'
      values={propertyValues}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={addPropertyFinancialsSchema}
    />
  )
}

export { AddFinancials }
