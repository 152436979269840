import { Column } from 'react-table'
import _ from "lodash"
import { useNavigate } from 'react-router-dom'
export const columns: Column<any>[] = [
	{
		Header: () => <th className='min-w-150px'>Name</th>,
		id: 'name',
		Cell: (props) => {
			return (
				<span className='text-dark fw-bolder text-hover-primary fs-6'>
					{_.capitalize(props.data[props.row.index].name)}
				</span>
			)
		},
	},
	{
		Header: () => <th className='min-w-140px'>Price</th>,
		id: "price",
		Cell: (props) => {
			return (
				<span
					className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
				>
					${props.data[props.row.index].Unit.priceUsd}
				</span>
			)
		}
	},
	{
		Header: () => <th className='min-w-140px'>State</th>,
		id: "state",
		Cell: (props) => {
			return (
				<span
					className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
				>
					{props.data[props.row.index].state}
				</span>
			)
		}
	},
	{
		Header: () => <th className='min-w-140px'>ZipCode</th>,
		id: "zipCode	",
		Cell: (props) => {
			return (
				<span
					className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
				>
					{props.data[props.row.index].postCode}
				</span>
			)
		}
	},
	{
		Header: () => <></>,
		id: "show_more",
		Cell: (props) => {
			const navigate = useNavigate()
			return (
				<button
					onClick={() => navigate(`/property/${props.data[props.row.index].id}`)}
					className='btn btn-primary'
				>
					Show More
				</button>
			)
		}
	}
]
