import {api} from 'app/services'
import type {IProperty, IPropertyEdit, IPropertyFinancials} from './_models'

const propertyApi = api.enhanceEndpoints({addTagTypes: ['Property']}).injectEndpoints({
  endpoints: (builder) => ({
    getPropertyList: builder.query<
      any,
      {page?: string | number; limit?: string | number; filter?: string}
    >({
      query: (params) => ({
        url: `/property`,
        method: 'GET',
        params,
      }),
      providesTags: (results) => {
        return results?.data?.rows?.length
          ? [
              ...results.data.rows.map(({id}: any) => ({type: 'Property', id} as const)),
              {type: 'Property', id: 'LIST'},
            ]
          : [{type: 'Property', id: 'LIST'}]
      },
    }),
    addProperty: builder.mutation<
      any,
      {data: Partial<IProperty & IPropertyFinancials>; token: string | null}
    >({
      query: ({data, token}) => ({
        url: '/property',
        body: data,
        method: 'POST',
        headers: {
          'x-access-token': token ?? '',
        },
      }),
      invalidatesTags: [{type: 'Property', id: 'LIST'}],
    }),
    addPropertyImages: builder.mutation<any, {files: any; id: string | number}>({
      query: ({id, files}) => ({
        url: `/property/${id}/add-images`,
        body: files,
        method: 'POST',
      }),
      invalidatesTags: (result, error, args) => [{type: 'Property', id: args.id}],
    }),
    editProperty: builder.mutation<
      any,
      {data: Partial<IPropertyEdit & IPropertyFinancials>; id: string; token: string}
    >({
      query: ({data, id, token}) => ({
        url: `/property/${id}/edit`,
        method: 'PATCH',
        headers: {
          'x-access-token': token ?? '',
        },
        body: data,
      }),
      invalidatesTags: (results, error, meta) => {
        return [{type: 'Property', id: meta.id}]
      },
    }),
    getPropertyById: builder.query<any, {id: string | number}>({
      query: ({id}) => ({
        method: 'GET',
        url: `/property/${id}/details`,
      }),
      providesTags: (result, error, meta) => {
        return [{type: 'Property', id: meta.id}]
      },
    }),
    addPropertyDetails: builder.mutation<
      any,
      {data: Array<{key?: string; value: string | number}>; id: number; token: string}
    >({
      query: ({id, token, data}) => ({
        url: `/property/${id}/add-description`,
        method: 'POST',
        body: {
          details: data,
        },
        headers: {
          'x-access-token': token,
        },
      }),
      invalidatesTags: (result, error, meta) => {
        return [{type: 'Property', id: meta.id}]
      },
    }),
    addPropertyFinancials: builder.mutation({
      query: ({
        id,
        data,
        token,
      }: {
        id: number | string
        data: IPropertyFinancials
        token: string
      }) => ({
        url: `/property/${id}/add-financials`,
        method: 'POST',
        body: data,
        headers: {
          'x-access-token': token,
        },
      }),
      invalidatesTags: (result, error, meta) => {
        return [{type: 'Property', id: meta.id}]
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetPropertyListQuery,
  useAddPropertyMutation,
  useAddPropertyDetailsMutation,
  useAddPropertyFinancialsMutation,
  useGetPropertyByIdQuery,
  useAddPropertyImagesMutation,
  useEditPropertyMutation,
} = propertyApi
