import { Route, Routes } from 'react-router-dom'
import { OrdersList } from './OrdersList'
import { PageLink, PageTitle } from '_metronic/layout/core'
const ordersBreadCrumbs: Array<PageLink> = [
    {
        title: 'Orders',
        path: '/orders',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const OrdersPage = () => {
    return (
        <Routes>
            <Route
                element={
                    <>
                        <PageTitle breadcrumbs={ordersBreadCrumbs}>{"List"}</PageTitle>
                        <OrdersList />
                    </>
                }
                index
            />
        </Routes>
    )
}
export default OrdersPage
