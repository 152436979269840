/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { toast } from 'react-toastify'
import { ID } from '_metronic/helpers'
import { useApproveUserMutation } from '../../core/api'
// import {useListView} from '../../core/ListViewProvider'
// import {useQueryResponse} from '../../core/QueryResponseProvider'
// import {deleteUser} from '../../core/_requests'

type Props = {
  id: ID
  approved: Boolean
}

const UserActionsCell: FC<Props> = ({ id, approved }) => {
  const [approveUser, approveUserResponse] = useApproveUserMutation()

  const handleClick = async () => {
    await approveUser({ id })
  }


  useEffect(() => {
    if (approveUserResponse.isSuccess) {
      console.log(approveUserResponse.data.Message)
      toast.success(approveUserResponse.data.Message)
    }
    //eslint-disable-next-line
  }, [approveUserResponse.isSuccess])


  return approved ? (
    <button className='btn btn-light btn-disabled btn-sm'>Already Approved</button>
  ) : (
    <button
      disabled={approveUserResponse.isLoading}
      onClick={handleClick}
      className={'btn btn-hover-light btn-primary btn-sm'}
    >
      {approveUserResponse.isLoading ? 'Please wait...' : 'Approve'}
    </button>
  )
}

export { UserActionsCell }
