import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Slide, SlideshowRef } from 'react-slideshow-image'
import { KTCard } from '_metronic/helpers'
import { useGetPropertyByIdQuery } from '../core/api'

const Images = () => {
  const { id } = useParams()
  const slideRef = React.useRef<SlideshowRef | null>(null)
  const propertyImages = useGetPropertyByIdQuery({ id: id! }, {
    selectFromResult: (result) => {
      console.log({ ...result.data?.data?.Images })
      return {
        ...result,
        data: result.data?.data?.Images ?? []
      }
    }
  })
  const navigate = useNavigate()
  useEffect(() => {
    if (propertyImages.isSuccess && propertyImages.data.length === 0) {
      navigate("add")
    }
    //eslint-disable-next-line
  }, [propertyImages.isSuccess])

  const properties = {
    duration: 5000,
    autoplay: false,
    transitionDuration: 500,
    arrows: true,
    infinite: true,
    easing: 'ease',
  }
  // function back() {
  //   slideRef.current?.goBack()
  // }

  // function next() {
  //   slideRef.current?.goNext()
  // }
  return (
    <KTCard className='position-relative'>
      <div className='card-header'>
        <div className='d-flex align-items-center justify-content-end w-100'>
          <button onClick={() => {
            navigate("add")
          }} className="btn btn-primary ms-auto">
            Add Images
          </button>
        </div>
      </div>
      <div className='card-body container max-w-500px' >

        <Slide ref={slideRef} {...properties} >
          {(propertyImages.data as string[]).map((each, index) => (
            <div key={index} className=' border-2 text-center d-flex align-items-center justify-content-center'>
              <img className='lazy w-75' src={each} alt={`each-${index}`} height={600} style={{ objectFit: "contain" }} />
            </div>
          ))}
        </Slide>
      </div>
      {/* <div style={{ position: "sticky", top: "50%", transform: "translateY(-50%)" }} className="w-100 px-10" >
        <div className='d-flex justify-content-between w-100'>
          <button onClick={back} type='button' className='rounded p-5 pointer btn btn-outline-primary'>
            <span className='bi bi-chevron-left fs-4 text-bold'></span>
          </button>
          <button onClick={next} type='button' className='rounded p-5 pointer btn btn-outline-primary'>
            <span className='bi bi-chevron-right fs-4 text-bold'></span>
          </button>
        </div>

      </div> */}
    </KTCard>
  )
}

export default Images
