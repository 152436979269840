import {FC} from 'react'

type Props = {
  status?: boolean
  text?: string
}

const UserStatusCell: FC<Props> = ({status, text}) => {
  return (
    <div className={`badge badge-light-${status ? 'success' : 'danger'} fw-bolder`}>{text}</div>
  )
}

export {UserStatusCell}
