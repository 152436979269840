import React from 'react'
import { PageLink, PageTitle } from '_metronic/layout/core'
const usersBreadCrumbs: Array<PageLink> = [
  {
    title: 'Users',
    path: '/users',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UsersPageTitle: React.FC<{}> = () => {
  // const {status} = useParams()
  // useValidateRoute(status, ['approved', 'unapproved'])
  return <PageTitle breadcrumbs={usersBreadCrumbs}>{"List"}</PageTitle>
}

export default UsersPageTitle
