import { FieldProps } from 'app/components/interface'
import TextField from 'app/components/TextField'
import React from 'react'
import { IPropertyDetails } from '../core/_models'

type Field = FieldProps & {
	renderField: (fieldProps: FieldProps, index: number) => React.ReactNode
}

export const getFields = (values: IPropertyDetails): Array<Field> => [
	{
		label: 'No. of Bathrooms',
		placeholder: 'No. of Bathrooms',
		name: 'bedroom',
		value: values.bedroom,
		type: 'number',
		customIcon: { path: '/media/svg/property/bathroom.svg', className: 'rotate-y-12' },
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'No. of Bedroom',
		placeholder: 'No. of Bedroom',
		name: 'bathroom',
		value: values.bathroom,
		type: 'number',
		customIcon: '/media/svg/property/bedroom.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'No. of Garage',
		placeholder: 'No. of Garage',
		name: 'garage',
		value: values.garage,
		type: 'number',
		customIcon: '/media/svg/property/garage.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'No. of Pools',
		placeholder: 'No. of Pools',
		name: 'pool',
		value: values.pool,
		type: 'number',
		customIcon: '/media/svg/property/pool.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'No. of Story',
		placeholder: 'No. of Story',
		name: 'story',
		value: values.story,
		type: 'number',
		customIcon: '/media/svg/property/story.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property Lot Size',
		placeholder: 'Property Lot Size',
		name: 'lotSize',
		value: values.lotSize,
		type: 'number',
		customIcon: '/media/svg/property/land-size.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property Land Size',
		placeholder: 'Property Landx Size',
		name: 'landSize',
		value: values.landSize,
		type: 'number',
		customIcon: '/media/svg/property/land-size.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Construction Type',
		placeholder: 'Property Construction Type',
		name: 'constructionType',
		value: values.constructionType,
		type: 'number',
		customIcon: '/media/svg/property/construction.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property Foundation Type',
		placeholder: 'Property Foundation Type',
		name: 'foundation',
		value: values.foundation,
		type: 'text',
		customIcon: '/media/svg/property/foundation.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property Roof Type',
		placeholder: 'Property Roof Type',
		name: 'roofType',
		value: values.roofType,
		type: 'text',
		customIcon: '/media/svg/property/roof.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},

	{
		label: 'Property Parking Size',
		placeholder: 'Property Parking Size',
		name: 'parking',
		value: values.parking,
		type: 'number',
		customIcon: '/media/svg/property/parking.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property Interior Size',
		placeholder: 'Property Interior Size',
		name: 'interiorSize',
		value: values.interiorSize,
		type: 'number',
		customIcon: '/media/svg/property/interior.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property Heating System',
		placeholder: 'Property Heating System',
		name: 'heating',
		value: values.heating,
		type: 'text',
		customIcon: '/media/svg/property/heating-system.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property Cooling System',
		placeholder: 'Property Cooling System',
		name: 'cooling',
		value: values.cooling,
		type: 'text',
		customIcon: '/media/svg/property/cooling.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property Furniture Type',
		placeholder: 'Property Furniture Type',
		name: 'furniture',
		value: values.furniture,
		type: 'text',
		customIcon: '/media/svg/property/furniture.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property Appliances Type',
		placeholder: 'Property Appliances Type',
		name: 'appliances',
		value: values.appliances,
		type: 'text',
		customIcon: '/media/svg/property/appliances.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property Type',
		placeholder: 'Property Type',
		name: 'propertyType',
		value: values.propertyType,
		type: 'text',
		customIcon: '/media/svg/property/property.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Exterior Walls',
		placeholder: 'Exterior Walls',
		name: 'exteriorWalls',
		value: values.exteriorWalls,
		type: 'text',
		customIcon: '/media/svg/property/property.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Renovated',
		placeholder: 'Renovated',
		name: 'renovated',
		value: values.renovated,
		type: 'text',
		customIcon: '/media/svg/property/property.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property Construction Year',
		placeholder: 'Property Construction Year',
		name: 'constructionYear',
		value: values.constructionYear,
		type: 'text',
		fontIcon: 'bi-calendar-check',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property Rental Type',
		placeholder: 'Property Rental Type',
		name: 'rentalType',
		value: values.rentalType,
		type: 'text',
		customIcon: '/media/svg/property/rental.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property Neighbourhood Type',
		placeholder: 'Property Neighbourhood Type',
		name: 'neighbourhood',
		value: values.neighbourhood,
		type: 'text',
		customIcon: '/media/svg/property/neighborhood.svg',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property Section8',
		placeholder: 'Property Section8',
		name: 'section8',
		value: values.section8,
		type: 'text',
		fontIcon: 'bi-house',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
]
