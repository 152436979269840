/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation, useParams } from 'react-router'
import { useGetPropertyByIdQuery } from '../core/api'
import _ from 'lodash'
import { PropertyLinks } from '../core/constants'
const PropertyHeader: React.FC = () => {
  const location = useLocation()
  const { id } = useParams()
  const baseUrl = `/property/${id}/`
  const property = useGetPropertyByIdQuery({ id: id! }, { selectFromResult: (results) => ({ ...results, data: results.data?.data }) })

  const propertyValues: Array<{ title: string; value: any }> = [
    {
      title: 'Price Usd',
      value: property.data.Unit.priceUsd,
    },
    {
      title: "Units",
      value: property.data.Unit.unitsQuantity
    }
  ]
  console.log({ property })
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img
                src={property.data?.imgThumbnail ?? "/property-image-default.jpg"}
                style={{ display: 'inline-block', objectFit: 'contain' }}
                alt={property.data?.name}
                className="border-1 px-1"
              />
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <Link to={baseUrl} className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {_.capitalize(property?.data.name)}
                  </Link>
                </div>
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  {propertyValues.map((v, idx) => (
                    <div key={idx} className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex flex-column align-items-center'>
                        <div className='fw-bold fs-6 text-gray-400'>{v.title}</div>
                        <div className='fs-2 fw-bolder'>{v.value}</div>
                      </div>
                    </div>
                  ))}

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            {PropertyLinks.map(link => (
              <li key={link.id} className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === baseUrl + link.relativeUrl && 'active')
                  }
                  to={baseUrl + link.relativeUrl}
                >
                  {link.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export { PropertyHeader }
