import React, { useEffect, useState } from 'react'
import { IPropertyFinancials, PropertyFinancialsFields } from '../core/_models'
import StandardForm from 'app/components/StandardForm'
import { addPropertyFinancialsSchema } from '../core/_schema'
import { getFields } from '../Financials/fields'
import clsx from 'clsx'
import { FormikProps } from 'formik'

interface Props {
  data: IPropertyFinancials
  prev: (values: Partial<IPropertyFinancials>) => void
  next: (values: Partial<IPropertyFinancials>) => void
}

interface ButtonsProps {
  formik: FormikProps<any>
  baseButtonTitle: string
}

const AddFinancials: React.FC<Props> = ({ data, next, prev }) => {
  const handleSubmit = (values: IPropertyFinancials) => {
    const newData = (Object.keys(values) as PropertyFinancialsFields[]).reduce(
      (obj: Partial<IPropertyFinancials>, v: PropertyFinancialsFields) => {
        if (!!values[v]) {
          obj[v] = parseInt(values[v].toString()) || 0
        }
        return obj
      },
      {}
    )
    next(newData)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    //eslint-disable-next-line
  }, [])

  const Buttons: React.FC<ButtonsProps> = ({ baseButtonTitle, formik }) => {
    const [onPreviousClick, setOnPreviousClick] = useState(false)
    return (
      <div className='d-flex w-100 justify-content-between align-items-center flex-grow-1'>
        <button
          type='button'
          className={clsx(
            'btn btn-primary w-25',
            onPreviousClick ? 'btn-secondary' : 'btn-primary'
          )}
          onClick={(
            e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>
          ) => {
            e.preventDefault()
            setOnPreviousClick(true)
            prev(formik.values)
          }}
        >
          {!onPreviousClick && 'Previous'}
          {onPreviousClick && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <button
          type='submit'
          className={clsx(
            'btn btn-primary w-25',
            formik.isSubmitting ? 'btn-secondary' : 'btn-primary'
          )}
        >
          {!formik.isSubmitting && baseButtonTitle}
          {formik.isSubmitting && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    )
  }

  return (
    <StandardForm
      getFields={getFields}
      title='Property Financials'
      submitTitle='Next'
      renderButton={(props) => <Buttons {...props} />}
      onSubmit={handleSubmit}
      initialValues={data}
      validationSchema={addPropertyFinancialsSchema}
    />
  )
}

export { AddFinancials }
