import { Table } from 'app/components/Table'
import React, { useState } from 'react'
import { KTCard } from '_metronic/helpers'
import { useGetOrdersQuery } from '../core/api'
import { columns } from './columns'

const OrdersList = () => {
	const [page, setPage] = useState(1)
	const [itemsPerPage, setItemsPerPage] = useState(25)
	const token = localStorage.getItem("auth_token") ?? ""
	const ordersResponse = useGetOrdersQuery(
		{ page: page, limit: itemsPerPage, token },
		{
			selectFromResult: (result) => ({
				...result,
				data: {
					rows: result.data?.orders.rows ?? [],
					count: result.data?.orders?.count ?? 0,
				},
			}),
		}
	)

	return (
		<KTCard className='' >
			<Table
				columns={columns}
				data={ordersResponse?.data?.rows ?? []}
				loading={ordersResponse.isLoading}
				pagination={true}
				page={page}
				setPage={setPage}
				itemsPerPage={itemsPerPage}
				setItemsPerPage={setItemsPerPage}
				totalCount={ordersResponse.data?.count ?? 0}
			/>
		</KTCard>
	)
}

export { OrdersList }
