import React from 'react'
import {useField} from 'formik'
import type {FieldProps} from './interface'
const TextArea: React.FC<FieldProps & {rows?: number}> = ({
  label,
	rows,
  ...fieldProps
}) => {
  const [field, meta] = useField(fieldProps)

  return (
    <div className='row mb-6'>
      <label className='col-lg-4 col-form-label fw-bold fs-6'>
        <span className='required'>{label}</span>
      </label>

      <div className='col-lg-8 fv-row'>
        <div className='input-group mb-3 text-light'>
          <textarea
            className='form-control form-control-lg form-control-solid'
            placeholder={fieldProps.placeholder}
            {...field}
						rows={rows}
          ></textarea>
        </div>

        {meta.touched && meta.error && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger'>{meta.error}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TextArea
