import React, { PropsWithChildren } from 'react'
import { Formik, FormikConfig, FormikProps } from 'formik'
import type { FieldProps } from './interface'
import clsx from 'clsx'

type Field = FieldProps & {
  renderField: (fieldProps: FieldProps, index: number) => React.ReactNode
}

interface StandardFormProps<V = any> extends FormikConfig<V> {
  getFields: (values: V) => Array<Field>
  title: string
  submitTitle: string
  values?: any
  renderButton?: (props: PropsWithChildren<{ formik: FormikProps<any>; baseButtonTitle: string }>) => JSX.Element
}

const defaultRenderButton = ({ baseButtonTitle, formik }: PropsWithChildren<{ baseButtonTitle?: string; formik: FormikProps<any>; }>) => {
  return <button
    type='submit'
    className={clsx(
      'btn btn-primary w-50',
      formik.isSubmitting ? 'btn-secondary' : 'btn-primary'
    )}
  >
    {!formik.isSubmitting && baseButtonTitle}
    {formik.isSubmitting && (
      <span className='indicator-progress' style={{ display: 'block' }}>
        Please wait...{' '}
        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
      </span>
    )}
  </button>
}

const StandardForm: React.FC<StandardFormProps> = ({
  getFields,
  title,
  submitTitle,
  values = {},
  renderButton = defaultRenderButton,
  ...formikProps
}) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{title}</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <Formik {...formikProps} initialValues={{ ...formikProps.initialValues, ...values }}>
          {(formik) => (
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body border-top p-9'>
                {getFields(formik.values).map(({ renderField, ...field }, index) =>
                  renderField(field, index)
                )}
              </div>
              <div className='card-footer d-flex justify-content-center py-6 px-9'>
                {renderButton({ baseButtonTitle: submitTitle, formik })}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default StandardForm
