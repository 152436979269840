import React, {useEffect, useState} from 'react'
import {IRent, rentInitialValues as initialValues} from '../core/_models'
import TextField from '../../../components/TextField'
import {addRentSchema} from '../core/_schema'
import {FieldProps} from 'app/components/interface'
import Select from 'app/components/Select'
import StandardForm from 'app/components/StandardForm'
import type {IOptions} from 'app/components/interface'
import {useGetPropertyListQuery} from 'app/modules/property/core/api'
import {useAddRentMutation} from '../core/api'
import { toast } from 'react-toastify'
import _ from "lodash"
type Field = FieldProps & {
  renderField: (fieldProps: FieldProps, index: number) => React.ReactNode
}

const AddRent: React.FC = () => {
  const propertyListResponse = useGetPropertyListQuery({})
  // const [propertyId, setPropertyId] = useState(0)
  const [options, setOptions] = useState<Array<IOptions>>([])

  useEffect(() => {
    if (propertyListResponse.isSuccess) {
      setOptions(
        (propertyListResponse.data?.data?.rows ?? []).map((item: any) => ({
          id: item.id,
          label: _.capitalize(item.name),
          value: '' + item.id,
        })) ?? null
      )
    }
    // eslint-disable-next-line
  }, [propertyListResponse.isSuccess])

  useEffect(() => {
    if (propertyListResponse.isError) {
      console.log({err: propertyListResponse.error})
    }
    // eslint-disable-next-line
  }, [propertyListResponse.isError])

  const getFields = (values: IRent): Array<Field> => [
    {
      label: 'Property',
      name: 'propertyId',
      placeholder: 'Select a Property',
      value: values.propertyId,
      type: 'select',
      renderField: (fieldProps, index) => <Select key={index} {...fieldProps} />,
      options,
    },
    {
      label: 'Rent Amount',
      name: 'rentAmount',
      placeholder: 'Enter Rent Amount',
      value: values.rentAmount,
      type: 'number',
      renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
    },
    {
      label: 'Expenditures Amount',
      name: 'expenditures',
      value: values.expenditures,
      placeholder: 'Enter Expenditures Amount',
      type: 'number',
      renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
    },
    {
      label: 'Net Rent Amount',
      name: 'netRentAmount',
      value: values.netRentAmount,
      placeholder: 'Enter Net Rent Amount',
      type: 'number',
      renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
    },
  ]

  // const [data, setData] = useState<IRent>(initialValues)
  // const updateData = (fieldsToUpdate: Partial<IRent>): void => {
  //   const updatedData = Object.assign(data, fieldsToUpdate)
  //   setData(updatedData)
  // }

  const [addRent, addRentResponse] = useAddRentMutation()

  const handleSubmit = async (values: IRent) => {
    let rentValues: IRent = {
      propertyId: +values.propertyId,
      rentAmount: +values.rentAmount,
      expenditures: +values.expenditures,
      netRentAmount: +values.netRentAmount,
    }
    // setPropertyId(+rentValues.propertyId)
    const token = localStorage.getItem('auth_token')! ?? ''
    await addRent({data: rentValues, token})
  }

  useEffect(() => {
    if (addRentResponse.isSuccess) {
      toast.success(`Rent was successfully added!`)
    }
    // eslint-disable-next-line
  }, [addRentResponse.isSuccess])

  return (
    <StandardForm
      getFields={getFields}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={addRentSchema}
      submitTitle={'Add'}
      title={'Add Rent to Property'}
    />
  )
}

export {AddRent}
