import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const api = createApi({
  reducerPath: 'api',
  tagTypes: ['Admin'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_API_URL}/api`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const token = localStorage.getItem("auth_token") ?? ""
      if(endpoint.toLowerCase().includes("user")){
        console.log({ })
        headers.set("x-access-token", token)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({}),
})
