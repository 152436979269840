interface IPropertyLinks {
	id: number;
	title: string;
	relativeUrl: string
}

export const PropertyLinks: Array<IPropertyLinks> = [
  {
    id: 1,
    title: 'Property',
    relativeUrl: '',
  },
  {
    id: 2,
    title: 'Description',
    relativeUrl: 'description',
  },
  {
    id: 3,
    title: 'Financials',
    relativeUrl: 'financials',
  },
  {
    id: 4,
    title: 'Images',
    relativeUrl: 'images',
  },
]

export const BASEURL = (id:string | number) => `/property/${id}`