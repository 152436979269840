/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useGetPropertyListQuery} from '../core/api'
import {KTCard} from '_metronic/helpers'
import {Table} from 'app/components/Table'
import {columns} from './columns'
import SearchFilter from 'app/components/SearchFilter'
type Props = {
  className: string
}

const PropertyList: React.FC<Props> = ({className}) => {
  const [page, setPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(25)
  const [searchFilter, setSearchFilter] = useState<string | undefined>(undefined)

  const getPropertyListResponse = useGetPropertyListQuery({
    page,
    limit: itemsPerPage,
    filter: searchFilter,
  })

  const handleSearch = (query: string) => {
    const newQuery = query.trim().toLowerCase()
    if(newQuery === ""){
      setSearchFilter(undefined)
      return
    }
    setSearchFilter(query)
  }

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Property List's</span>
          </h3>
          <SearchFilter handleSearch={handleSearch} searchPlaceholder='Search Property' />
        </div>
        <Table
          columns={columns}
          data={getPropertyListResponse.data?.data?.rows ?? []}
          loading={getPropertyListResponse.isLoading || getPropertyListResponse.isFetching}
          pagination={true}
          page={1}
          itemsPerPage={itemsPerPage}
          setPage={setPage}
          setItemsPerPage={setItemsPerPage}
          totalCount={getPropertyListResponse.data?.data?.count ?? 0}
        />
      </KTCard>
    </>
  )
}

export {PropertyList}
