type Mapper<T> = {
  [key in keyof T]: any
}

export const getNonEmptyProperties = <T = {}>(values: Mapper<T>) => {
  return (Object.keys(values) as (keyof T)[]).reduce((obj: Partial<Mapper<T>>, v: keyof T) => {
    if (!!values[v]) {
      obj[v] = parseInt(values[v].toString()) || 0
    }
    return obj
  }, {})
}
