import React from 'react'
import { useField } from 'formik'
import { FieldProps, IOptions } from './interface'
const Select: React.FC<FieldProps & { options?: IOptions[] | never }> = ({
  label,
  options,
  ...fieldProps
}) => {
  const [field, meta] = useField(fieldProps)
  
  return (
    <div className='row mb-6'>
      <label className='col-lg-4 col-form-label required fw-bold fs-6'>{label}</label>

      <div className='col-lg-8 fv-row'>
        <select className='form-select form-select-solid form-select-lg' {...field} >
          <option value=''>{fieldProps.placeholder}</option>
          {!!options?.length ? (
            options?.map((opt) => (
              <option value={opt.value} key={opt.id}>
                {opt.label}
              </option>
            ))
          ) : (
            <p>Loading...</p>
          )}
        </select>
        {meta.error && meta.touched && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger'>{meta.error}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Select
