import {useDispatch} from 'react-redux'
import {nextImage, previousImage} from '../core/slice'

const UserKycImageSelector = () => {
  const dispatch = useDispatch()

  const handleArrowRight = () => {
    dispatch(nextImage())
  }
  const handleArrowLeft = () => {
    dispatch(previousImage())
  }
  return (
    <div
      className='d-flex'
      style={{
        justifyContent: 'space-between',
        margin: '0 20px',
        width: '92%',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
    >
      <div style={{cursor: 'pointer'}} onClick={handleArrowLeft}>
        <i className='bi bi-arrow-left fs-3 text-dark' />
      </div>

      <div style={{cursor: 'pointer'}} onClick={handleArrowRight}>
        <i className='bi bi-arrow-right fs-3 text-dark' />
      </div>
    </div>
  )
}

export default UserKycImageSelector
