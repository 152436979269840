import { useCallback, useEffect } from 'react'
import { TableInstance } from 'react-table'

export type ColumnSetterType = TableInstance['setHiddenColumns']

export const useUpdateColumns = <T extends string | number | symbol>(
	handler: ((func: ColumnSetterType, ...dependecyArray: T[]) => void) | null | undefined,
	columnSetter: ColumnSetterType,
	dependencyArray: T[]
) => {
	const memoizedHandler = useCallback(() => {
		return handler !== undefined && handler !== null ? handler(columnSetter, ...dependencyArray) : null
		//eslint-disable-next-line
	}, [handler, ...dependencyArray])
	useEffect(() => {
		if (memoizedHandler !== null) {
			memoizedHandler()
		}
		//eslint-disable-next-line
	}, [...dependencyArray])
}
