import React from 'react'
import {useField} from 'formik'
import type {CustomIconType, FieldProps} from './interface'
import clsx from 'clsx'
import {KTSVG} from '_metronic/helpers'
const TextField: React.FC<FieldProps & {fontIcon?: string; customIcon?: CustomIconType}> = ({
  label,
  fontIcon,
  customIcon,
  ...fieldProps
}) => {
  const [field, meta] = useField(fieldProps)

  return (
    <div className='row mb-6'>
      <label className='col-lg-4 col-form-label fw-bold fs-6'>
        <span className='required'>{label}</span>
      </label>

      <div className='col-lg-8 fv-row'>
        <div className='input-group mb-3 text-light'>
          {fontIcon && <span className={clsx('input-group-text border-0 bi', fontIcon)}></span>}

          {customIcon &&
            (typeof customIcon === 'object' ? (
              <span className='input-group-text border-0'>
                <KTSVG
                  path={customIcon.path}
                  className={clsx('svg-icon-2', customIcon?.className)}
                  svgClassName={customIcon?.svgClassName ?? "mh-50px"}
                />
              </span>
            ) : (
              <span className='input-group-text border-0'>
                <KTSVG path={customIcon} className='svg-icon-2' />
              </span>
            ))}
          <input
            className='form-control form-control-lg form-control-solid'
            placeholder={fieldProps.placeholder}
            {...field}
          />
        </div>

        {meta.touched && meta.error && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger'>{meta.error}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TextField
