import { Navigate, Routes, Route, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '_metronic/layout/core'
import { Details, Edit } from './Details'
import { PropertyList } from './PropertyList'
import PropertyWrapper from './components/PropertyWrapper'
import { AddDescription, Description } from './Description'
import { Financials, AddFinancials, EditFinancials } from './Financials'
import PropertyForm from './AddProperty/PropertyForm'
import Images from './Images/Images'
import AddImages from './Images/Add'

const propertyBreadCrumbs: Array<PageLink> = [
  {
    title: 'Property',
    path: '/property',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const PropertyPage = () => {
  return (
    <Routes>
      <Route
        path='list'
        element={
          <>
            <PageTitle breadcrumbs={propertyBreadCrumbs}>Property Listing</PageTitle>
            <PropertyList className='cart-container' />
          </>
        }
      />

      <Route
        path='add'
        element={
          <>
            <PageTitle breadcrumbs={propertyBreadCrumbs}>Add Property</PageTitle>
            <PropertyForm />
          </>
        }
      />
      <Route
        path=':id'
        element={
          <PropertyWrapper
            renderTitle={(name) => <PageTitle breadcrumbs={propertyBreadCrumbs}>{name}</PageTitle>}
          />
        }
      >
        <Route index element={<Details />} />
        <Route path='edit' element={<Edit />} />
        <Route path='description' element={<Outlet />}>
          <Route index element={<Description />} />
          <Route path='add' element={<AddDescription />} />
        </Route>
        <Route path='financials' element={<Outlet />}>
          <Route index element={<Financials />} />
          <Route path='add' element={<AddFinancials />} />
          <Route path='edit' element={<EditFinancials />} />
        </Route>
        <Route path='images' element={<Images />} />
        <Route path='images/add' element={<AddImages />} />
      </Route>
      <Route index element={<Navigate to='list' />} />
    </Routes>
  )
}

export default PropertyPage
