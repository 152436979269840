import React from 'react'
import _ from 'lodash'
interface Props {
	title: string
	entries: Record<string, any>,
	renderButton: () => JSX.Element
}

export const EntriesList: React.FC<Props> = ({ title, entries, renderButton }) => {
	return (
		<div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
			<div className='card-header cursor-pointer'>
				<div className='card-title m-0'>
					<h3 className='fw-bolder m-0'>{title}</h3>
				</div>
				{renderButton()}
			</div>

			<div className='card-body p-9'>
				{Object.keys(entries).map((e) => {
					return (
						<div key={e} className='row mb-7'>
							<label className='col-lg-4 fw-bold text-muted'>{e.replace(/([A-Z])/g, " $1").split(" ").reduce((string, curr) => string += `${_.capitalize(curr)} `, "")}</label>

							<div className='col-lg-8'>
								<span className='fw-bolder fs-6 text-dark'>{entries[e]}</span>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}
