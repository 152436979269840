import React, { useEffect } from 'react'
import {
  IPropertyDetails,
  PropertyDetailsFields,
  propertyDetailsInitialValues as initialValues,
} from '../core/_models'
import StandardForm from 'app/components/StandardForm'
import { useNavigate, useParams } from 'react-router-dom'
import { useAddPropertyDetailsMutation, useGetPropertyByIdQuery } from '../core/api'
import { addPropertyDetailsSchema } from '../core/_schema'
import { toast } from 'react-toastify'
import { getFields } from './fields'
import _ from 'lodash'
const AddDescription: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    if (!id || typeof id === 'undefined') {
      navigate('/property/list', { replace: true })
    }
    // eslint-disable-next-line
  }, [])

  const { data: values } = useGetPropertyByIdQuery(
    { id: id! },
    {
      selectFromResult: (result) => {
        const data = result.data?.data ?? {}
        let validEntries = {
          ...initialValues,
          ...(!data?.Description ? {} : data.Description),
        }
        validEntries = _.pick(validEntries, Object.keys(initialValues))
        return {
          ...result,
          data: validEntries,
        }
      },
    }
  )

  const [addPropertyDetails, addPropertyDetailsResponse] = useAddPropertyDetailsMutation()

  const handleSubmit = async (values: IPropertyDetails, { setSubmitting, resetForm }: any) => {
    setSubmitting(true)
    let proeprtyDetailsValues = (Object.keys(values) as PropertyDetailsFields[])
      .filter((key) => !!values[key])
      .map((e) => ({ key: e, value: values[e] }))
    const token = localStorage.getItem('auth_token') ?? ''
    await addPropertyDetails({ id: parseInt(id!), data: proeprtyDetailsValues, token })
    resetForm()
    setSubmitting(false)
  }

  useEffect(() => {
    if (addPropertyDetailsResponse.isSuccess) {
      navigate(`/property/${id}/description`, { replace: true })
      toast.success(addPropertyDetailsResponse.data.Message)
    }
    // eslint-disable-next-line
  }, [addPropertyDetailsResponse.isSuccess])

  useEffect(() => {
    if (addPropertyDetailsResponse.isError) {
      console.log({ error: addPropertyDetailsResponse.error })
    }
    // eslint-disable-next-line
  }, [addPropertyDetailsResponse.isError])

  return (
    <StandardForm
      getFields={getFields}
      title='Property Description'
      submitTitle='Submit'
      onSubmit={handleSubmit}
      initialValues={initialValues}
      values={values}
      validationSchema={addPropertyDetailsSchema}
    />
  )
}

export { AddDescription }
