import { api } from 'app/services'
import { ILogin } from './_models'

const authApi = api.enhanceEndpoints({ addTagTypes: ["Admin"] }).injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<any, { data: ILogin }>({
      query: ({ data }) => ({
        url: '/auth/admin-login',
        method: 'POST',
        body: data,
      }),
    }),
    currentUser: builder.query<any, { token: string }>({
      query: ({ token }) => ({
        url: '/auth/me',
        headers: {
          'x-access-token': token,
        },
        method: 'GET',
      }),
      providesTags: ["Admin"]
    }),
  }),
})

export const { useLoginMutation, useLazyCurrentUserQuery, useCurrentUserQuery } = authApi
