import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { KTCard, KTCardBody } from '_metronic/helpers'
import { FileAttr } from '../AddProperty/UploadMultipleImages'
import { useAddPropertyImagesMutation } from '../core/api'

const AddImages = () => {
    const [files, setFiles] = useState<Array<any>>([])
    const fileUploadRef = useRef<HTMLInputElement | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [addImages, addImagesResponse] = useAddPropertyImagesMutation()
    const { id } = useParams()
    const handleSubmit = async (files: FileAttr[], _: { setLoading: React.Dispatch<React.SetStateAction<boolean>> }) => {
        if (files.length === 0) {
            return
        }
        setSubmitting(true)
        const formData = new FormData()
        for (let file of files) {
            formData.append('image', file.file)
        }
        await addImages({ files: formData, id: id! })
        setSubmitting(false)
    }

    const navigate = useNavigate()
    useEffect(() => {
        if (addImagesResponse.isSuccess) {
            navigate(`/property/${id}/images`)
            toast.success(addImagesResponse.data.Message)
        }
        //eslint-disable-next-line
    }, [addImagesResponse.isSuccess])

    useEffect(() => {
        if (addImagesResponse.isError) {
            toast.error((addImagesResponse.error as any).data.errors[0].title)
        }
        //eslint-disable-next-line
    }, [addImagesResponse.isError])

    const handleOpenInputBox = () => {
        if (files.length >= 20) {
            toast.error('You cannot add more than 20 images')
            return
        }
        fileUploadRef?.current?.click()
    }

    const handleClose = (event: React.SyntheticEvent<HTMLElement>, id: number | string) => {
        setFiles((prevFiles) => {
            return prevFiles.filter((f) => f.id !== id)
        })
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(true)
        const newFiles = event.target.files && Array.from(event.target.files)
        if (!newFiles?.length) {
            event.target.value = ''
            setLoading(false)
            return
        }

        if (newFiles.length + files.length > 20) {
            toast.error('You have exceed the limit. Please try again')
            event.target.value = ''
            setLoading(false)
            return
        }

        let savedFiles: FileAttr[] = newFiles.map((f, idx) => ({
            file: f,
            id: files.length + idx + 1,
            main: false,
        }))

        setFiles((prevFiles) => {
            const allFiles = [...prevFiles, ...savedFiles]
            allFiles[0].main = true
            return allFiles
        })

        event.target.value = ''
        setLoading(false)
        console.log(event.target.files)
    }

    // const changeMain = () => {}

    useEffect(() => {
        window.scrollTo(0, 0)
        //eslint-disable-next-line
    }, [])

    return (
        <KTCard>
            <div className='card-header'>
                <div className="d-flex align-items-center min-w-100px">
                    <span onClick={() => {
                        navigate(-1)
                    }} className="bi bi-chevron-left  bg-primary text-light fw-bold btn text-center px-4"></span>
                </div>
            </div>
            <KTCardBody>
                <div className='alert alert-primary d-flex align-items-center' role='alert'>
                    <span className='bi bi-info flex-shrink-0 me-2 fs-2'></span>
                    <div>Please select an image as a thumbnail</div>
                </div>
                <form id='frm' className='needs-validation'>
                    <div
                        className='row'
                    >
                        {submitting && (
                            <div className='col-12 order-1 mt-2'>
                                <div
                                    data-type='progress'
                                    className='progress'
                                    style={{ height: 25 }}
                                >
                                    <div
                                        data-type='progressBar'
                                        className='progress-bar progress-bar-striped progress-bar-animated bg-success'
                                        role='progressbar'
                                        style={{ width: '100%' }}
                                    >
                                        Load in progress...
                                    </div>
                                </div>
                            </div>
                        )}
                        {(files.length < 20 ? [...files, null] : [...files]).map((item, idx) => (
                            <div
                                data-type='image-model'
                                className='col-4 pl-2 pr-2 pt-2'
                                key={idx + 1}
                                style={{ maxWidth: 200, position: 'relative' }}
                                onClick={() => {
                                    if (item) {
                                        setFiles((prevFiles) => {
                                            console.log({ prevFiles })
                                            return prevFiles.map((f) =>
                                                f?.id === item.id ? { ...f, main: !f.main } : { ...f, main: false }
                                            )
                                        })
                                    }
                                }}
                            >
                                <div
                                    className='ratio-box text-center'
                                    style={{ height: 180 }}
                                    data-type='image-ratio-box'
                                >
                                    {item === null && (
                                        <img
                                            data-type='noimage'
                                            alt={"Add"}
                                            className='btn btn-light ratio-img img-thumbnail d-block p-2 image border dashed rounded h-100 object-fit-contain'
                                            src='/image-upload.svg'
                                            onClick={handleOpenInputBox}
                                        />
                                    )}
                                    {loading && (
                                        <div data-type='loading' className='img-loading' style={{ color: '#218838' }}>
                                            <span className='fa fa-2x fa-spin fa-spinner'></span>
                                        </div>
                                    )}

                                    {item !== null && (
                                        <img
                                            data-type='preview'
                                            alt={item.file.name}
                                            className='btn btn-light ratio-img img-thumbnail p-2 image border dashed rounded h-100 object-fit-contai d-block'
                                            src={URL.createObjectURL(item.file)}
                                        />
                                    )}
                                    {item !== null && item.main && (
                                        <span
                                            className='badge pill bg-info'
                                            style={{ position: 'absolute', top: 150, right: 65 }}
                                        >
                                            Thumbnail
                                        </span>
                                    )}
                                </div>
                                <div className='row justify-content-center mt-2'>
                                    {!item ? (
                                        <button
                                            key='0'
                                            className='btn btn-primary w-50'
                                            type='button'
                                            onClick={handleOpenInputBox}
                                        >
                                            <span className='fa fa-camera me-2'> </span>Add
                                        </button>
                                    ) : (
                                        <button
                                            key='1'
                                            className='btn btn-danger w-75'
                                            type='button'
                                            onClick={(e) => handleClose(e, item.id)}
                                        >
                                            <span className='fa fa-trash me-2'> </span>Remove
                                        </button>
                                    )}
                                </div>
                                {/* <ModifyPopover id={idx} files={files} /> */}
                            </div>
                        ))}
                    </div>
                    <div className='form-group row'>
                        <div className='input-group'>
                            <input
                                ref={fileUploadRef}
                                id='files'
                                type='file'
                                name='files[]'
                                onChange={handleFileChange}
                                data-button=''
                                multiple={true}
                                accept='image/jpeg, image/png, image/gif,'
                                style={{ display: 'none' }}
                            />
                        </div>
                    </div>
                    <div className='col-md-4 offset-md-4 text-center mb-4 mt-7'>
                        <button
                            id='btnContinue'
                            type='submit'
                            disabled={files.length === 0}
                            className='btn  btn-primary float-right w-100 mx-2'
                            onClick={(e) => {
                                e.preventDefault()
                                // if (files.length > 0) {
                                handleSubmit(files, { setLoading: setSubmitting })
                                // } else {
                                // toast.error('Please select atleast on image file')
                                // }
                            }}
                        >
                            {!submitting ? (
                                <>
                                    Upload
                                    <span id='btnContinueIcon' className='fa fa-chevron-circle-right ms-3'></span>
                                </>
                            ) : (
                                <span id='btnContinueLoading' className='fa fa-spin fa-spinner ml-2'></span>
                            )}
                        </button>
                    </div>
                </form>

            </KTCardBody>
        </KTCard>
    )
}

export default AddImages