import React, { useEffect } from 'react'
import StandardForm from 'app/components/StandardForm'
import { editPropertyFields } from '../formFields/edit-property'
import { IPropertyEdit, PropertyEditFields, propertyEditInitialValues } from '../core/_models'
import { useEditPropertyMutation, useGetPropertyByIdQuery } from '../core/api'
import { useNavigate, useParams } from 'react-router-dom'
// import { diff, observableDiff, applyChange } from 'deep-diff'
import _ from 'lodash'
import { toast } from 'react-toastify'
const Edit: React.FC = () => {
	const params = useParams()
	const propertyByIdResponse = useGetPropertyByIdQuery(
		{ id: params.id! },
		{
			selectFromResult: ({ data, ...extras }) => {
				return {
					data: {
						..._.omit(data.data, [
							'priceUsd',
							'imgThumbnail',
							'createdAt',
							'updatedAt',
							'Unit',
							'Images',
							'Description',
						]),
						units: data.data.Unit.unitsQuantity,
					} as IPropertyEdit,
					...extras,
				}
			},
		}
	)

	useEffect(() => {
		if (propertyByIdResponse.isSuccess) {
			console.log({ data: propertyByIdResponse.data })
		}
		// eslint-disable-next-line
	}, [propertyByIdResponse.isSuccess])


	const [editProperty, editPropertyResponse] = useEditPropertyMutation()

	const handleSubmit = async (values: IPropertyEdit, { setSubmitting, resetForm }: any) => {
		setSubmitting(true)
		const data: Partial<IPropertyEdit> | any = {}
		for (let k in values) {
			const typedKey = k as PropertyEditFields
			if (values[typedKey] !== propertyByIdResponse.data[typedKey]) {
				data[typedKey] = values[typedKey]
			}
		}
		const token = localStorage.getItem('auth_token')!
		await editProperty({ data, token, id: params.id! })
		setSubmitting(false)
	}
	const navigate = useNavigate()
	useEffect(() => {
		if (editPropertyResponse.isSuccess) {
			toast.success(editPropertyResponse.data?.Message)
			navigate(`/property/${params.id}/`, { replace: true })
		}
		// eslint-disable-next-line
	}, [editPropertyResponse.isSuccess])

	useEffect(() => {
		if (editPropertyResponse.isError) {
			toast.error((editPropertyResponse.error as any).data.errors[0].title)
		}
		// eslint-disable-next-line
	}, [editPropertyResponse.isError])
	return (
		<StandardForm
			getFields={editPropertyFields}
			title={'Edit Property'}
			submitTitle='Submit'
			initialValues={propertyEditInitialValues}
			onSubmit={handleSubmit}
			values={propertyByIdResponse.data}
		/>
	)
}

export { Edit }
