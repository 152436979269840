import { FieldProps } from 'app/components/interface'
import TextField from 'app/components/TextField'
import { IPropertyFinancials } from '../core/_models'

type Field = FieldProps & {
	renderField: (fieldProps: FieldProps, index: number) => React.ReactNode
}

export const getFields = (values: IPropertyFinancials): Array<Field> => [
	{
		label: 'Gross Rent Per Year',
		placeholder: 'Gross Rent Per Year',
		name: 'grossRentPerYear',
		value: values.grossRentPerYear,
		type: 'number',
		fontIcon: 'bi-currency-dollar',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Gross Rent Per Month',
		placeholder: 'Gross Rent Per Month',
		name: 'grossRentPerMonth',
		value: values.grossRentPerMonth,
		type: 'number',
		fontIcon: 'bi-currency-dollar',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property Maintanence Charges',
		name: 'propertyManagementCharges',
		placeholder: 'Property Maintanence Charges',
		value: values.propertyManagementCharges,
		type: 'number',
		fontIcon: 'bi-currency-dollar',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Maintanence Charges',
		placeholder: 'Maintanence Charges',
		name: 'maintenanceCharges',
		value: values.maintenanceCharges,
		type: 'number',
		fontIcon: 'bi-currency-dollar',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property Tax Charges',
		name: 'propertyTaxCharges',
		placeholder: 'Property Tax Charges',
		value: values.propertyTaxCharges,
		type: 'number',
		fontIcon: 'bi-currency-dollar',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Platform Charges',
		name: 'platformCharges',
		placeholder: 'Platform Charges',
		value: values.platformCharges,
		type: 'number',
		fontIcon: 'bi-currency-dollar',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Insurance Charges',
		name: 'insuranceCharges',
		placeholder: 'Insurance Charges',
		value: values.insuranceCharges,
		type: 'number',
		fontIcon: 'bi-currency-dollar',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Utility Charges',
		name: 'utilityCharges',
		placeholder: 'Utility Charges',
		value: values.utilityCharges,
		type: 'number',
		fontIcon: 'bi-currency-dollar',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Net Rent Per Month',
		name: 'netRentPerMonth',
		placeholder: 'Net Rent Per Month',
		value: values.netRentPerMonth,
		type: 'number',
		fontIcon: 'bi-currency-dollar',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Net Rent Per Year',
		placeholder: 'Net Rent Per Year',
		name: 'netRentPerYear',
		value: values.netRentPerYear,
		type: 'number',
		fontIcon: 'bi-currency-dollar',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Asset Price Investment',
		placeholder: 'Asset Price Investment',
		name: 'assetPriceInvestment',
		value: values.assetPriceInvestment,
		type: 'number',
		fontIcon: 'bi-currency-dollar',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Platform Listing Fee Investment',
		placeholder: 'Platform Listing Fee Investment',
		name: 'platformListingFeeInvestment',
		value: values.platformListingFeeInvestment,
		type: 'number',
		fontIcon: 'bi-currency-dollar',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Initial Maintenance Reserve Investment',
		placeholder: 'Initial Maintenance Reserve Investment',
		name: 'initialMaintenanceReserveInvestment',
		value: values.initialMaintenanceReserveInvestment,
		type: 'number',
		fontIcon: 'bi-currency-dollar',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Miscellaneous Cost Investment',
		placeholder: 'Miscellaneous Cost Investment',
		name: 'miscellaneousCostsInvestment',
		value: values.miscellaneousCostsInvestment,
		type: 'number',
		fontIcon: 'bi-currency-dollar',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Expected Income Percentage',
		placeholder: 'Expected Income Percentage',
		name: 'expectedIncomePercentage',
		value: values.expectedIncomePercentage,
		type: 'number',
		fontIcon: 'bi-currency-dollar',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
]
