import { UserKycImageModal } from './user-kyc-images/UserKycImage'
import { KTCard } from '_metronic/helpers'
import { useSelector } from 'react-redux'
import { useGetUsersListQuery } from './core/api'
import { useMemo, useState } from 'react'
import { Table } from 'app/components/Table'
import { usersColumns } from './table/columns/_columns'
import SearchFilter from 'app/components/SearchFilter'

export const UsersList: React.FC<{ approved: boolean }> = ({ approved }) => {
  const { currentImages } = useSelector((state: any) => state.users)
  const [page, setPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(25)
  const [filter, setFilter] = useState<string | undefined>(undefined)

  const apiParams = useMemo(() => {
    if (filter === undefined) {
      return {
        approved,
        page,
        limit: itemsPerPage,
        filter,
      }
    }
    return {
      filter,
      approved,
    }
    //eslint-disable-next-line
  }, [approved, page, itemsPerPage, filter])

  const userListResponse = useGetUsersListQuery(apiParams)
  const handleSearch = (query: string) => {
    const newQuery = query.toString().toLowerCase().trim()
    if (newQuery === "") {
      setFilter(undefined)
      return
    }
    setFilter(newQuery)
  }

  console.log("Is Fetching", userListResponse.isFetching)

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <div className='card-toolbar'>
            <SearchFilter handleSearch={handleSearch} searchPlaceholder="Search Users" />
          </div>
        </div>
        <Table
          columns={usersColumns}
          data={userListResponse?.data?.rows ?? []}
          loading={userListResponse.isLoading}
          pagination={true}
          page={page}
          setPage={setPage}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          totalCount={userListResponse.data?.count ?? 0}
          initialState={{ hiddenColumns:  approved ? ["Actions"] : [] }}
        />
      </KTCard>
      {currentImages && <UserKycImageModal />}
    </>
  )
}
