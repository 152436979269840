/* eslint-disable react/jsx-no-target-blank */
// import { useIntl } from "react-intl";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import type { Props as AsideMenuItemWithSubProps } from "./AsideMenuItemWithSub";

export function AsideMenuMain() {
  // const intl = useIntl();

  return (
    <>
      {/* <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/art/art002.svg"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      /> */}
      <div className="menu-item">
        <div className="menu-content pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Modules
          </span>
        </div>
      </div>
      <AsideMenuItemWithSub
        title={"Property"}
        to="/property"
        icon={"/media/icons/duotune/finance/fin001.svg"}
        fontIcon={"bi-house"}
      >
        {(function () {
          const list: AsideMenuItemWithSubProps[] = [
            { title: "List", to: "/property/list", hasBullet: true },
            { title: "Add Property", to: "/property/add", hasBullet: true },
          ];
          return list.map((item) => (
            <AsideMenuItem key={item.title} {...item} />
          ));
        })()}
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        title={"Rent"}
        to="/rent"
        icon={"/media/icons/duotune/finance/fin001.svg"}
        fontIcon={"bi-house"}
      >
        {(function () {
          const list: AsideMenuItemWithSubProps[] = [
            { title: "Add", to: "/rent/add", hasBullet: true }
          ];
          return list.map((item) => (
            <AsideMenuItem key={item.title} {...item} />
          ));
        })()}
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        title={"Users"}
        to="/users"
        // icon={"/media/icons/duotune/finance/fin001.svg"}
        // fontIcon={"bi-house"}
        hasBullet={true}
      >
        {(function () {
          const list: AsideMenuItemWithSubProps[] = [
            {
              title: "Approved",
              to: "/users/approved",
              hasBullet: true
            },
            {
              title: "Unapproved",
              to: "/users/unapproved",
              hasBullet: true
            },
          ];
          return list.map((item) => (
            <AsideMenuItem key={item.title} {...item} />
          ));
        })()}
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        title={"Orders"}
        to="/orders"
        // icon={"/media/icons/duotune/finance/fin001.svg"}
        // fontIcon={"bi-house"}
        hasBullet={true}
      >
        {(function () {
          const list: AsideMenuItemWithSubProps[] = [
            {
              title: "List",
              to: "/orders/",
              hasBullet: true
            },
          ];
          return list.map((item) => (
            <AsideMenuItem key={item.title} {...item} />
          ));
        })()}
      </AsideMenuItemWithSub>
    </>
  );
}
