import React, { FC, useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useGetPropertyByIdQuery } from '../core/api'
import { PropertyHeader } from './PropertyHeader'

interface IPropertyWrapper {
	renderTitle: (name: string) => JSX.Element | React.ReactNode
}

const PropertyWrapper: FC<IPropertyWrapper> = ({ renderTitle }) => {
	const { id } = useParams()
	const navigate = useNavigate()
	if (!id) {
		navigate('/property/list', { replace: true })
	}
	const propertyByIdResponse = useGetPropertyByIdQuery({ id: id! })
	useEffect(() => {
		// eslint-disable-next-line
	}, [propertyByIdResponse.isSuccess])

	useEffect(() => {
		if (propertyByIdResponse.isError) {
			console.log({ error: propertyByIdResponse.error })
		}
		// eslint-disable-next-line
	}, [propertyByIdResponse.isError])
	return (
		<>
			{propertyByIdResponse.isLoading && <p>Loading...</p>}
			{propertyByIdResponse.isSuccess && (
				<>
					{renderTitle(propertyByIdResponse.data.data.name)}
					<PropertyHeader />
					<Outlet />
				</>
			)}
		</>
	)
}

export default PropertyWrapper
