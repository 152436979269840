import {api} from 'app/services'

export const userAPI = api.enhanceEndpoints({addTagTypes: ['Users']}).injectEndpoints({
  endpoints: (builder) => ({
    getUsersList: builder.query<
      any,
      {page?: number | string; limit?: string | number; filter?: string; approved?: boolean}
    >({
      query: (params) => ({
        url: `/users`,
        method: 'GET',
        params,
      }),
      providesTags: (results) => {
        console.log({results})
        return typeof results?.rows !== undefined
          ? [
              ...results.rows.map(({id}: any) => ({type: 'Users', id} as const)),
              {type: 'Users', id: 'LIST'},
            ]
          : [{type: 'Users', id: 'LIST'}]
      },
      transformResponse: (results, meta, arg) => {
        const {data} = results as any
        const response = {
          rows: data?.rows ?? [],
          count: data?.count ?? 0,
        }

        return response
      },
    }),
    approveUser: builder.mutation({
      query: ({id}) => ({
        method: 'PATCH',
        url: `/users/${id}/approve`,
      }),
      invalidatesTags: (results, error, arg) => {
        return [{type: 'Users', id: arg.id}]
      },
    }),
  }),
})

export const {useGetUsersListQuery, useApproveUserMutation} = userAPI
