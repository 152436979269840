/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {KTSVG} from '../../../helpers'
import {AsideMenu} from './AsideMenu'
import AsideFooter from './AsideFooter'
const AsideDefault: FC = () => {
  const {config, classes} = useLayout()
  const {aside} = config
  const [isMinimized, setIsMInimized] = useState(aside.minimize)
  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside[0])}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      {/* begin::Brand */}
      <div className='aside-logo flex-column-auto'>
        {/* begin::Logo */}

        <Link to='/dashboard' className='d-block text-left font-weight-bold fs-3 text-light'>
          {isMinimized && 'Smart Crowd Admin'}
        </Link>

        {/* end::Logo */}

        {/* begin::Aside toggler */}
        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
            onClick={() => {
              setTimeout(() => {
                setIsMInimized((prevIsMinimized) => !prevIsMinimized)
              }, 100)
            }}
          >
            <KTSVG
              path={'/media/icons/duotune/arrows/arr080.svg'}
              className={'svg-icon-1 rotate-180'}
            />
          </div>
        )}
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}
      <AsideFooter hide={isMinimized} />
      {/* end::Footer */}
    </div>
  )
}

export {AsideDefault}
