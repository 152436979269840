import { Column } from "react-table";

export const columns: Column<any>[] = [
    {
        Header: "OrderId",
        id: "id",
        Cell: (props) => {
            return <p className="min-w-100px fs-6 text-bold">#{props.data[props.row.index].id}</p>
        }
    },
    {
        Header: "User",
        id: "userName",
        Cell: (props) => {
            return <p className="min-w-100px fs-6 fw-bold">{props.data[props.row.index].firstName} {props.data[props.row.index].lastName}</p>
        }
    },
    {
        Header: "Property",
        id: "property",
        Cell: (props) => {
            const transactions = props.data[props.row.index].Transactions
            return <p className="min-w-100px fs-6 fw-bold">{transactions.length > 0 ? transactions[0].Property.name : ""}</p>
        }
    },
    {
        Header: "Amount",
        id: "amount",
        Cell: (props) => {
            const transactions = props.data[props.row.index].Transactions
            const amount = transactions.reduce((amt: number, t: any) => amt + parseFloat(t.subTotal), 0)
            return <p className="min-w-100px fs-6 text-dark fw-bold">Rs {parseFloat(amount).toFixed(3)}</p>
        }
    },
    {
        Header: "Date",
        id: "date",
        Cell: (props) => {
            return <p className="min-w-100px fs-6 text-bold">{new Date(props.data[props.row.index].createdAt).toDateString()}</p>
        }
    },
    {
        Header: "Status",
        id: "status",
        Cell: (props) => {
            return <p className="min-w-100px fs-6 text-bold badge pill badge-success text-center p-1 px-2">{props.data[props.row.index].status.trim()}</p>
        }
    },

]