import { configureStore } from '@reduxjs/toolkit'
import { api } from './services'
import authReducer from 'app/modules/auth/core/slice'
import usersReducer from 'app/modules/users/core/slice'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
const store =  configureStore({
	reducer: {
		[api.reducerPath]: api.reducer,
		auth: authReducer,
	users: usersReducer
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware)
})
export type RootState = ReturnType<typeof store.getState>


setupListeners(store.dispatch)
export default store