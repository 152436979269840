import {useEffect} from 'react'
import {UserKycImageHeader} from './UserKycImageHeader'
import {useSelector} from 'react-redux'
import UserKycImageSelector from './UserKycImageSelector'
const UserKycImageModal = () => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const currentImage = useSelector((state: any) => {
    return state.users.currentImages[state.users.currentImageIndex]
  })

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px' style={{ position: "relative" }}>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <UserKycImageHeader title={currentImage.title} />
            {/* begin::Modal body */}
            <div className='modal-body d-flex align-items-center scroll-y mx-5 mx-xl-15 my-7'>
              <img
                src={currentImage.src}
                width='80%'
                style={{objectFit: 'contain', margin: "0 auto"}}
                height={300}
                alt={currentImage.title}
              />
            </div>
            {/* end::Modal body */}
            <UserKycImageSelector />
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {UserKycImageModal}
