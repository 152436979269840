import {createSlice} from '@reduxjs/toolkit'

interface IState {
  users: Array<any | null>
  currentImages?: Array<ICurrentImage> | null
  currentImageIndex: number
}

interface ICurrentImage {
  title: string
  src: string
}

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    currentImages: null,
    currentImageIndex: 0,
  } as IState,
  reducers: {
    setUsersList(state: IState, {payload}: {payload: {data: Array<any | null>}}) {
      state.users = payload.data
    },
    setCurrentImage(state: IState, {payload}: {payload: {images: Array<ICurrentImage> | null}}) {
      state.currentImages = payload.images
    },
    nextImage(state: IState) {
      if (state.currentImages?.length! - 1 !== state.currentImageIndex) {
        state.currentImageIndex += 1
      }
    },
    previousImage(state: IState){
      if(state.currentImageIndex !== 0){
        state.currentImageIndex -= 1
      }
    }
  },
})

export const {setUsersList, setCurrentImage, nextImage, previousImage} = usersSlice.actions
export default usersSlice.reducer
