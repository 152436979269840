import TextField from 'app/components/TextField'
import type { FieldProps } from 'app/components/interface'
import Select from 'app/components/Select'
import TextArea from 'app/components/TextArea'
import { IPropertyEdit } from "../core/_models"
type Field = FieldProps & {
	renderField: (fieldProps: FieldProps, index: number) => React.ReactNode
}
export const editPropertyFields = (values: IPropertyEdit): Array<Field> => [
	{
		label: 'Property Name',
		placeholder: 'Name',
		name: 'name',
		value: values.name,
		type: 'text',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property Country',
		name: 'country',
		placeholder: 'Country',
		value: values.country,
		type: 'text',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property State',
		name: 'state',
		value: values.state,
		placeholder: 'State',
		type: 'text',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property County',
		name: 'county',
		placeholder: 'County',
		value: values.county,
		type: 'text',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property City',
		name: 'city',
		placeholder: 'City',
		value: values.city,
		type: 'text',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Country Initials',
		name: 'countryInitials',
		placeholder: 'Country Initials',
		value: values.countryInitials,
		type: 'text',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Post Code',
		name: 'postCode',
		placeholder: 'PostCode',
		value: values.postCode,
		type: 'text',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Property Class',
		placeholder: 'Property Class',
		name: 'class',
		value: values.class,
		type: 'select',
		options: [
			{
				id: 1,
				label: 'Residence',
				value: 'residence',
			},
		],
		renderField: (fieldProps, index) => <Select key={index} {...fieldProps} />,
	},
	{
		label: 'Latitude',
		placeholder: 'Latitude',
		name: 'latitude',
		value: values.latitude,
		type: 'number',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'Longitude',
		placeholder: 'Longitude',
		name: 'longitude',
		value: values.longitude,
		type: 'number',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
	{
		label: 'About',
		placeholder: 'About',
		name: 'about',
		rows: 5,
		value: values.about,
		type: 'multiline',
		renderField: (fieldProps, index) => <TextArea key={index} {...fieldProps} />,
	},
	{
		label: 'Units',
		placeholder: 'Units',
		name: 'units',
		value: values.units,
		type: 'number',
		renderField: (fieldProps, index) => <TextField key={index} {...fieldProps} />,
	},
]
