export interface IRent {
  propertyId: number | string;
  rentAmount: number;
  expenditures: number;
  netRentAmount: number;
}

export const rentInitialValues = {
    propertyId: "",
    rentAmount: 0,
    expenditures: 0,
    netRentAmount: 0
};
