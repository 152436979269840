import {useAuth} from 'app/modules/auth'
import clsx from 'clsx'
import React from 'react'
import {toAbsoluteUrl} from '_metronic/helpers'
import {useLayout} from '_metronic/layout/core'
import {HeaderUserMenu} from '_metronic/partials'
interface AsideFooterProps {
  hide?: Boolean
}

const asideFooterButtonMarginClass = 'ms-1 ms-lg-3',
  asideFooterUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const AsideFooter: React.FC<AsideFooterProps> = ({hide}) => {
  const {currentUser} = useAuth()
  const {config} = useLayout()
  const {aside} = config
  
  return (
    <div className='aside-footer flex-column-auto pt-5 pb-7 px-5' id='kt_aside_footer'>
      <div
        className={clsx('d-flex align-items-center', asideFooterButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className='d-flex flex-row align-items-center justify-content-space-between'
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <div className={clsx('cursor-pointer symbol', asideFooterUserAvatarHeightClass)}>
            <img
              src={toAbsoluteUrl('/media/avatars/300-1.jpg')}
              alt='metronic'
              width={50}
              height={50}
            />
          </div>
          {hide && (
            <div className='d-flex flex-column px-2'>
              <div className='fw-bolder text-white d-flex align-items-center fs-5'>
                {currentUser?.first_name} {currentUser?.first_name}
              </div>
              <a href='/#' className='fw-bold text-muted text-hover-primary fs-7'>
                {currentUser?.email}
              </a>
            </div>
          )}
        </div>

        <HeaderUserMenu hide={aside.minimize} />
        {/* end::Toggle */}
      </div>
    </div>
  )
}

export default AsideFooter
