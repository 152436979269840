import {api} from 'app/services'

const orderApi = api.enhanceEndpoints({addTagTypes: ['Orders']}).injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query<any, {page?: number; limit?: number; token: string}>({
      query: ({token, ...params}) => ({
        url: '/order',
        params,
        method: 'GET',
        headers: {
          'x-access-token': token,
        },
      }),
    }),
  }),
})

export const {useGetOrdersQuery} = orderApi
