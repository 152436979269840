import React, { useMemo } from 'react'
import { Column, ColumnInstance, Row, TableState, useTable } from 'react-table'
import { CustomHeader } from './CustomHeader'
import { CustomRow } from './CustomRow'
import { KTCardBody } from '_metronic/helpers'
import { Loader } from '../Loader'
import {
  ColumnSetterType,
  useUpdateColumns,
} from 'app/hooks/useUpdateColumns'
import { Pagination } from './Pagination'

interface Props {
  data: any[]
  columns: Readonly<Column<any>>[]
  loading: boolean
  initialState?: Partial<TableState<any>>
  handleHideColumns?: ((func: ColumnSetterType, ...dependecyArray: any[]) => void) | null
  parameters?: any[]
}

type PaginationProps =
  | {
    pagination: true
    totalCount: number
    itemsPerPage: number
    setItemsPerPage: React.Dispatch<React.SetStateAction<number>>
    page: number
    setPage: React.Dispatch<React.SetStateAction<number>>
  }
  | {
    pagination?: never
    totalCount?: never
    page?: never
    itemsPerPage?: never
    setItemsPerPage?: never
    setPage?: never
  }

const Table: React.FC<Props & PaginationProps> = (props) => {
  const data = useMemo(() => {
    return props.data
  }, [props.data])
  const columns = useMemo(() => {
    return props.columns
    //eslint-disable-next-line
  }, [])

  const { rows, headerGroups, getTableBodyProps, getTableProps, prepareRow, setHiddenColumns } =
    useTable({
      columns,
      data,
      initialState: props.initialState,
    })

  useUpdateColumns<any>(props?.handleHideColumns, setHiddenColumns, props.parameters ?? [])

  const pageCount = useMemo(() => {
    if (
      props.pagination === true &&
      props.itemsPerPage !== undefined &&
      props.totalCount !== undefined
    ) {
      return Math.ceil(props.totalCount / props.itemsPerPage)
    }
    return 0
    // eslint-disable-next-line
  }, [props.itemsPerPage, props.totalCount])

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_assigned_courses'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className='text-start text-bold fw-bolder fs-7 text-uppercase gs-0'
              >
                {headerGroup.headers.map((column: ColumnInstance<any>) => (
                  <CustomHeader key={column.id} column={column} />
                ))}
              </tr>
            ))}
          </thead>
          <tbody className='text-gray-600 ' {...getTableBodyProps()}>
            {rows.length > 0 &&
              !props.loading &&
              rows.map((row: Row<any>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={i} />
              })}
            {rows.length === 0 && !props.loading && (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {props.loading && (
          <div className='container py-5'>
            <Loader />
          </div>
        )}
      </div>
      {props.pagination && (
        <Pagination
          page={props.page}
          pageCount={pageCount}
          handlePage={(e, page) => props.setPage(page)}
          handleNext={(e) => props.setPage((p) => p + 1)}
          handlePrevious={(e) => props.setPage((p) => p - 1)}
          loading={props.loading}
          itemsPerPage={props.itemsPerPage}
          handleItemsPerPage={(e, items) => props.setItemsPerPage(items)}
        />
      )}
    </KTCardBody>
  )
}

export { Table }
