export function getFinancialData (data: any) {

    const financials = (({
        assetPriceInvestment,
        expectedIncomePercentage,
        grossRentPerMonth,
        grossRentPerYear,
        initialMaintenanceReserveInvestment,
        insuranceCharges,
        maintenanceCharges,
        miscellaneousCostsInvestment,
        netRentPerYear,
        netRentPerMonth,
        platformCharges,
        platformListingFeeInvestment,
        propertyManagementCharges,
        propertyTaxCharges,
        utilityCharges
      }) => ({
        assetPriceInvestment,
        expectedIncomePercentage,
        grossRentPerMonth,
        grossRentPerYear,
        initialMaintenanceReserveInvestment,
        insuranceCharges,
        maintenanceCharges,
        miscellaneousCostsInvestment,
        netRentPerYear,
        netRentPerMonth,
        platformCharges,
        platformListingFeeInvestment,
        propertyManagementCharges,
        propertyTaxCharges,
        utilityCharges
      }))(data)
      return financials
}