import _ from 'lodash'

export interface IProperty {
  name: string
  country: string
  state: string
  county: string
  city: string
  countryInitials: string
  postCode: string | number
  class: 'residence' | ''
  latitude: string | number
  longitude: string | number
  about: string | any
  units: string | number | any
  priceUsd: string | number | any
}
export type PropertyEditFields = keyof IPropertyEdit
export type PropertyDetailsFields =
  | 'bedroom'
  | 'bathroom'
  | 'garage'
  | 'pool'
  | 'story'
  | 'landSize'
  | 'lotSize'
  | 'constructionType'
  | 'foundation'
  | 'roofType'
  | 'parking'
  | 'interiorSize'
  | 'heating'
  | 'cooling'
  | 'furniture'
  | 'appliances'
  | 'propertyType'
  | 'constructionYear'
  | 'neighbourhood'
  | 'rentalType'
  | 'section8'
  | 'exteriorWalls'
  | 'renovated'

export interface IPropertyDetails extends Record<PropertyDetailsFields, string | number> {}

export type PropertyFinancialsFields =
  | 'grossRentPerYear'
  | 'grossRentPerMonth'
  | 'propertyManagementCharges'
  | 'platformCharges'
  | 'maintenanceCharges'
  | 'propertyTaxCharges'
  | 'insuranceCharges'
  | 'utilityCharges'
  | 'netRentPerMonth'
  | 'netRentPerYear'
  | 'assetPriceInvestment'
  | 'platformListingFeeInvestment'
  | 'initialMaintenanceReserveInvestment'
  | 'miscellaneousCostsInvestment'
  | 'expectedIncomePercentage'

export type IPropertyFinancials = Record<PropertyFinancialsFields, number | string>

export interface IPropertyEdit extends Omit<IProperty, 'priceUsd'> {}
export interface APIResponse<T> {
  type: string
  data?: T
  apiresponse: boolean
}

export const propertyDetailsInitialValues: Required<IPropertyDetails> = {
  bedroom: '',
  bathroom: '',
  garage: '',
  pool: '',
  story: '',
  lotSize: '',
  landSize: '',
  constructionType: '',
  foundation: '',
  roofType: '',
  parking: '',
  interiorSize: '',
  heating: '',
  cooling: '',
  furniture: '',
  appliances: '',
  propertyType: '',
  constructionYear: '',
  neighbourhood: '',
  rentalType: '',
  section8: '',
  exteriorWalls: "",
  renovated: ""
}

export const propertyFinancialsInitialValues: IPropertyFinancials = {
  grossRentPerYear: '',
  grossRentPerMonth: '',
  propertyManagementCharges: '',
  platformCharges: '',
  maintenanceCharges: '',
  propertyTaxCharges: '',
  insuranceCharges: '',
  utilityCharges: '',
  netRentPerMonth: '',
  netRentPerYear: '',
  assetPriceInvestment: '',
  platformListingFeeInvestment: '',
  initialMaintenanceReserveInvestment: '',
  miscellaneousCostsInvestment: '',
  expectedIncomePercentage: '',
}

export const propertyInitalValues: IProperty = {
  name: '',
  country: '',
  state: '',
  county: '',
  city: '',
  countryInitials: '',
  postCode: '',
  class: '',
  latitude: '',
  longitude: '',
  about: '',
  priceUsd: '',
  units: '',
}
export const propertyEditInitialValues: IPropertyEdit = _.omit(propertyInitalValues, ['priceUsd'])
