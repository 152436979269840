/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
type Props = {
  info?: string | null
}

const UserInfoCell: React.FC<Props> = ({info}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='d-flex flex-column'>
      <a href='#' className='text-gray-800 text-hover-primary mb-1'>
        {info}
      </a>
    </div>
  </div>
)

export {UserInfoCell}
