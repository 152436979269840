import React, {useCallback, useEffect, useState} from 'react'
import {KTSVG} from '_metronic/helpers'
import {useDebounce} from '_metronic/helpers'
interface Props {
  searchPlaceholder?: string
  handleSearch: (query: string) => void
}

const SearchFilter: React.FC<Props> = ({searchPlaceholder = '', handleSearch}) => {
  const [searchTerm, setSearchTerm] = useState('')

  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const searchCb = useCallback((searchQuery: string) => {
    handleSearch(searchQuery)
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      searchCb(debouncedSearchTerm)
    }
    //eslint-disable-next-line
  }, [debouncedSearchTerm])

  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder={searchPlaceholder}
          value={searchTerm}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
        />
      </div>
      {/* end::Search */}
    </div>
  )
}

export default SearchFilter
