import React, { useEffect } from 'react'
import {
	IPropertyFinancials,
	propertyFinancialsInitialValues as initialValues,
} from '../core/_models'
import StandardForm from 'app/components/StandardForm'
import { addPropertyFinancialsSchema } from '../core/_schema'
import { useGetPropertyByIdQuery } from '../core/api'
import { useNavigate, useParams } from 'react-router-dom'
import { getFields } from './fields'
// import { toast } from 'react-toastify'

const EditFinancials: React.FC = () => {
	const { id } = useParams()
	const navigate = useNavigate()
	useEffect(() => {
		if (!id || typeof id === 'undefined') {
			navigate('/property/list', { replace: true })
		}
		// eslint-disable-next-line
	}, [])

	const { data: values } = useGetPropertyByIdQuery({ id: id! }, {
		selectFromResult: (result) => {
			const data = result.data?.data ?? {}
			let validEntries = Object.keys(data).reduce((obj: any, item: any) => {
				if (Object.keys(initialValues).includes(item)) {
					return { ...obj, [item]: parseInt(data[item]) || 0 }
				}
				return obj
			}, {})
			return {
				...result,
				data: validEntries
			}
		}
	})


	// const [addPropertyFinancials, addPropertyFinancialsResponse] = useAddPropertyFinancialsMutation()

	const handleSubmit = async (values: IPropertyFinancials, { setSubmitting, resetForm }: any) => {
		setSubmitting(true)
		let data: IPropertyFinancials = {
			assetPriceInvestment: parseInt(values.assetPriceInvestment.toString()) || 0,
			expectedIncomePercentage: parseInt(values.expectedIncomePercentage.toString()) || 0,
			grossRentPerMonth: parseInt(values.grossRentPerMonth.toString()) || 0,
			grossRentPerYear: parseInt(values.grossRentPerYear.toString()) || 0,
			initialMaintenanceReserveInvestment: parseInt(values.initialMaintenanceReserveInvestment.toString()) || 0,
			insuranceCharges: parseInt(values.insuranceCharges.toString()) || 0,
			maintenanceCharges: parseInt(values.maintenanceCharges.toString()) || 0,
			miscellaneousCostsInvestment: parseInt(values.miscellaneousCostsInvestment.toString()) || 0,
			netRentPerMonth: parseInt(values.netRentPerMonth.toString()) || 0,
			netRentPerYear: parseInt(values.netRentPerYear.toString()) || 0,
			platformCharges: parseInt(values.platformCharges.toString()) || 0,
			platformListingFeeInvestment: parseInt(values.platformListingFeeInvestment.toString()) || 0,
			propertyManagementCharges: parseInt(values.propertyManagementCharges.toString()) || 0,
			propertyTaxCharges: parseInt(values.propertyTaxCharges.toString()) || 0,
			utilityCharges: parseInt(values.utilityCharges.toString()) || 0,
		}

		const token = localStorage.getItem("auth_token") ?? ""
		console.log({ data, token })
		// await addPropertyFinancials({
		// 	id: +id!,
		// 	data,
		// 	token
		// })
		setSubmitting(false)
		resetForm()
	}
	// useEffect(() => {
	// 	if (addPropertyFinancialsResponse.isSuccess) {
	// 		toast.success(addPropertyFinancialsResponse.data.Message)
	// 		navigate(`/property/${id}/financials`, { replace: true })
	// 	}
	// 	// eslint-disable-next-line
	// }, [addPropertyFinancialsResponse.isSuccess])

	// useEffect(() => {
	// 	if (addPropertyFinancialsResponse.isError) {
	// 		toast.error((addPropertyFinancialsResponse.error as any).data.errors[0].title)
	// 	}
	// 	// eslint-disable-next-line
	// }, [addPropertyFinancialsResponse.isError])

	return (
		<StandardForm
			getFields={getFields}
			title='Property Financials'
			submitTitle='Submit'
			values={values}
			onSubmit={handleSubmit}
			initialValues={initialValues}
			validationSchema={addPropertyFinancialsSchema}
		/>
	)
}

export { EditFinancials }
