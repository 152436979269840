import clsx from 'clsx'
import React, { useCallback, useMemo } from 'react'

interface Props {
  pageCount: number
  itemsPerPage: number
  handleItemsPerPage: (e: React.ChangeEvent<HTMLSelectElement>, items: number) => void
  page: number
  handlePage: (e: React.MouseEvent<HTMLElement>, page: number) => void
  handlePrevious: (e: React.MouseEvent<HTMLElement>) => void
  handleNext: (e: React.MouseEvent<HTMLElement>) => void
  loading: boolean
}

export const Pagination: React.FC<Props> = React.memo(
  ({
    pageCount,
    page,
    itemsPerPage,
    handlePage,
    handleItemsPerPage,
    loading,
    handleNext,
    handlePrevious,
  }) => {
    const filterPages = (visiblePages: number[] | null, totalPages: number): number[] => {
      return visiblePages?.filter((page) => page <= totalPages) ?? []
    }

    const getVisiblePages = useCallback(
      (page: number, total: number) => {
        if (total < 7) {
          return filterPages([1, 2, 3, 4, 5, 6], total)
        } else {
          if (page % 5 >= 0 && page > 4 && page + 2 < total) {
            return [1, page - 1, page, page + 1, total]
          } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
            return [1, total - 3, total - 2, total - 1, total]
          } else {
            return [1, 2, 3, 4, 5, total]
          }
        }
      },
      //eslint-disable-next-line
      [page, pageCount]
    )

    const pages = useMemo(() => {
      return getVisiblePages(page, pageCount)
      //eslint-disable-next-line
    }, [page, pageCount])

    const options = [
      { id: 1, value: 25, label: 25 },
      { id: 2, value: 50, label: 50 },
      { id: 3, value: 75, label: 75 },
      { id: 4, value: 100, label: 100 },
    ]

    return (
      <div className='row'>
        <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
          <div className='col-lg-3 fv-row p-3 border-dark '>
            <select
              className='form-select form-select-lg bg-light'
              style={{ width: 200 }}
              value={itemsPerPage}
              onChange={(e) => handleItemsPerPage(e, parseInt(e.target.value) || 25)}
            >
              {!!options?.length ? (
                options?.map((opt) => (
                  <option value={opt.value} data-name={opt.label} key={opt.id}>
                    {opt.label}
                  </option>
                ))
              ) : (
                <p>Loading...</p>
              )}
            </select>
          </div>
        </div>
        <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
          <div id='kt_table_users_paginate'>
            <ul className='pagination'>
              <li
                className={clsx('page-item previous', {
                  disabled: page === 1,
                })}
              >
                <button
                  className='page-link'
                  onClick={handlePrevious}
                  disabled={page === 1}
                  style={{ cursor: 'pointer' }}
                >
                  &laquo; Previous
                </button>
              </li>
              {pages.map((p, idx) => (
                <li
                  key={idx + 1}
                  className={clsx('page-item', {
                    active: page === p,
                    disabled: loading,
                  })}
                >
                  <button
                    className='page-link'
                    onClick={(e) => handlePage(e, +p)}
                    style={{ cursor: 'pointer' }}
                  >
                    {pages[idx - 1] + 2 < p ? `...  ${p}` : p}
                  </button>
                </li>
              ))}
              <li
                className={clsx('page-item next', {
                  disabled: pageCount === page,
                })}
              >
                <button
                  className='page-link'
                  onClick={handleNext}
                  disabled={page === pageCount}
                  style={{ cursor: 'pointer' }}
                >
                  Next &raquo;
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
  // propsAreEqual
)
