/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useGetPropertyByIdQuery } from '../core/api'
import { EntriesList } from 'app/components/EntriesList'
import { Loader } from 'app/components/Loader'
import { getFinancialData } from 'app/util'
import { getNonEmptyProperties } from 'app/util/getNonEmptyProperties'
import { IPropertyFinancials } from '../core/_models'

const Financials: React.FC = () => {
  const params = useParams()
  const propertyByIdResponse = useGetPropertyByIdQuery(
    { id: params.id! },
    {
      selectFromResult: (result) => {
        let data = result?.data?.data || {} as Partial<IPropertyFinancials> | any
        data = getFinancialData(data) as Partial<IPropertyFinancials>
        data = getNonEmptyProperties<IPropertyFinancials>(data)
        return {
          ...result,
          data,
        }
      },
    }
  )

  const navigate = useNavigate()

  useEffect(() => {
    if (!propertyByIdResponse.isFetching && Object.keys(propertyByIdResponse.data).length === 0) {
      navigate('add')
    }
    //eslint-disable-next-line
  }, [propertyByIdResponse.isFetching])

  if (propertyByIdResponse.isLoading || propertyByIdResponse.isFetching) {
    return <Loader />
  }

  return (
    <EntriesList
      title='Property Financials'
      entries={propertyByIdResponse.data}
      renderButton={() => (
        <Link to='add' className='btn btn-primary align-self-center'>
          Edit
        </Link>
      )}
    />
  )
}
export { Financials }
