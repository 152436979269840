import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
// import {UserLastLoginCell} from './UserLastLoginCell'
import {UserStatusCell} from './UserStatusCell'
import {UserActionsCell} from './UserActionsCell'
// import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
// import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import {UserShowKycImagesCell} from './UserShowKycImagesCell'
import _ from "lodash"
const usersColumns: Column<User>[] = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='First Name' className='min-w-125px' />
    ),
    id: 'firstName',
    Cell: ({...props}) => <UserInfoCell info={_.capitalize(props.data[props.row.index].firstName)} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Last Name' className='min-w-125px' />
    ),
    id: 'lastName',
    Cell: ({...props}) => <UserInfoCell info={_.capitalize(props.data[props.row.index].lastName)} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Email' className='min-w-125px' />
    ),
    id: 'email',
    Cell: ({...props}) => <UserInfoCell info={props.data[props.row.index].email} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-100px' />
    ),
    id: 'is_approved',
    Cell: ({...props}) => {
      return (
        <UserStatusCell
          text={!!props.data[props.row.index].approved ? 'Approved' : 'Unapproved'}
          status={!!props.data[props.row.index].approved}
        />
      )
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='US Citizen' className='min-w-100px' />
    ),
    id: 'is_usCitizen',
    Cell: ({...props}) => (
      <UserStatusCell
        text={props.data[props.row.index].isUsCitizen ? 'Yes' : 'No'}
        status={props.data[props.row.index].isUsCitizen}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Kyc Images' className='min-w-100px' />
    ),
    id: 'kycImages',
    Cell: ({...props}) => {
      const user = props.data[props.row.index]
      return <UserShowKycImagesCell frontImage={user.kycFrontImage} backImage={user.kycBackImage} />
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions ' className='min-w-100px' />
    ),
    id: 'Actions',
    Cell: ({...props}) => {
      return (
        <UserActionsCell
          id={props.data[props.row.index].id}
          approved={props.data[props.row.index].approved}
        />
      )
    },
  },
]

export {usersColumns}
