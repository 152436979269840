import * as Yup from 'yup'

const addPropertySchema = Yup.object().shape({
  name: Yup.string().required('Property name is required'),
  country: Yup.string().required('Property Country is required'),
  city: Yup.string().required('Propert City is required'),
  county: Yup.string().required('PropertyCounty is required'),
  state: Yup.string().required('Property State is required'),
  countryInitials: Yup.string()
    .min(2, 'Length of country initials should be between 2 and 5.')
    .max(5, 'Length of country initials should be between 2 and 5.')
    .required('Country Initals is required'),
  postCode: Yup.string().required('Postal Code is required'),
  class: Yup.string().required('Property Class i  s required'),
  latitude: Yup.number().required('Property Latitude is required'),
  longitude: Yup.number().required('Property Longitude is required'),
  about: Yup.string().required('About is a required field'),
  units: Yup.number().required('Units is a required field'),
  priceUsd: Yup.number().required('Price Usd is a required field'),
})

const ERROR_NEGATIVE = 'Negative Numbers are not allowed'
const ONLY_INTEGER = 'Decimals/Float are now allowed'
const addPropertyDetailsSchema = Yup.object({
  bedroom: Yup.number().positive(ERROR_NEGATIVE).integer(ONLY_INTEGER).optional(),
  bathroom: Yup.number().positive(ERROR_NEGATIVE).integer(ONLY_INTEGER).optional(),
  garage: Yup.number().positive(ERROR_NEGATIVE).integer(ONLY_INTEGER).optional(),
  pool: Yup.number().positive(ERROR_NEGATIVE).integer(ONLY_INTEGER).optional(),
  story: Yup.number().positive(ERROR_NEGATIVE).integer(ONLY_INTEGER).optional(),
  lotSize: Yup.number().optional().positive(ERROR_NEGATIVE),
  landSize: Yup.number().optional().positive(ERROR_NEGATIVE),
  constructionType: Yup.string().optional(),
  foundation: Yup.string().optional(),
  roofType: Yup.string().optional(),
  parking: Yup.number().optional(),
  interiorSize: Yup.number().optional().positive(ERROR_NEGATIVE),
  heating: Yup.string().optional(),
  cooling: Yup.string().optional(),
  furniture: Yup.string().optional(),
  appliances: Yup.string().optional(),
  propertyType: Yup.string().optional(),
  exteriorWalls: Yup.string().optional(),
  renovated: Yup.string().optional(),
  constructionYear: Yup.string()
    .length(4, 'Year can only contain 4 digits')
    .matches(/[0-9]{4}$/, 'Can only Contains digits')
    .optional(),
  neighbourhood: Yup.string().optional(),
  rentalType: Yup.string().optional(),
  section8: Yup.string().optional(),
})

const financials = [
  'grossRentPerYear',
  'grossRentPerMonth',
  'propertyManagementCharges',
  'platformCharges',
  'maintenanceCharges',
  'propertyTaxCharges',
  'insuranceCharges',
  'insuranceCharges',
  'netRentPerMonth',
  'netRentPerYear',
  'assetPriceInvestment',
  'platformListingFeeInvestment',
  'initialMaintenanceReserveInvestment',
  'miscellaneousCostsInvestment',
  'expectedIncomePercentage',
]

const addPropertyFinancialsSchema = Yup.object(
  financials.reduce((obj, curr) => ({...obj, [curr]: Yup.number().optional()}), {})
)

export {addPropertySchema, addPropertyDetailsSchema, addPropertyFinancialsSchema}
