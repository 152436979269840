import { FC, memo } from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentImage } from '../../core/slice'

type Props = {
  frontImage: string
  backImage: string
}

const UserShowKycImagesCell: FC<Props> = memo(({ backImage, frontImage }) => {
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(
      setCurrentImage({
        images: [
          {
            title: 'Kyc Front Image',
            src: frontImage,
          },
          {
            title: 'Kyc Back Image',
            src: backImage,
          },
        ],
      })
    )
  }

  return (
    <div
      onClick={handleClick}
      className={`text-align-center border-0 cursor-pointer w-min-150px p-2 fw-bolder`}
    >
      {'Show'}
    </div>
  )
})

export { UserShowKycImagesCell }
