import { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAddPropertyImagesMutation, useAddPropertyMutation } from '../core/api'
import {
	propertyInitalValues,
	propertyFinancialsInitialValues,
	IPropertyFinancials,
	IProperty,
	PropertyFinancialsFields,
} from '../core/_models'
import _ from 'lodash'
import { AddFinancials } from './AddFinancials'
import { AddProperty } from './AddProperty'
import UploadMultipleImages, { FileAttr, Options } from './UploadMultipleImages'
const PropertyForm = () => {
	const [data, setData] = useState<IPropertyFinancials & IProperty & { files: Array<any> }>({
		...propertyInitalValues,
		...propertyFinancialsInitialValues,
		files: [],
	})

	console.log(data)

	const [step, setStep] = useState(0)

	const next = (values: Partial<IPropertyFinancials> | IProperty) => {
		setData((prevData) => ({ ...prevData, ...values }))
		setStep((s) => s + 1)
	}

	const prev = (values: Partial<{ files: FileAttr[] } | IProperty | IPropertyFinancials>) => {
		setData((prevData) => ({ ...prevData, ...values }))
		setStep((s) => s - 1)
	}

	const navigate = useNavigate()
	const [addProperty] = useAddPropertyMutation()
	const [addImages] = useAddPropertyImagesMutation()
	const token = localStorage.getItem('auth_token') ?? ''
	const handleSubmit = async (files: FileAttr[], { setLoading }: Options) => {
		setLoading(true)
		const propertyData = _.omit(data, 'files')
		const newData = (
			Object.keys(propertyData) as (keyof IProperty & PropertyFinancialsFields)[]
		).reduce((obj: Partial<IPropertyFinancials>, v: PropertyFinancialsFields) => {
			if (!!propertyData[v]) {
				obj[v] = propertyData[v]
			}
			return obj
		}, {})
		return await addProperty({
			data: newData,
			token,
		})
			.unwrap()
			.then(async (value) => {
				if (!files.length) {
					return
				}
				const formData = new FormData()
				for (let file of files) {
					formData.append('image', file.file)
				}
				return await addImages({ files: formData, id: value.data?.id! }).unwrap()
			})
			.then((images) => {
				setLoading(false)
				toast.success('Property has been added successfully')
				navigate('/property/list', { replace: true })
			})
			.catch((error: any) => {
				setLoading(false)
				toast.error(error.data.errors[0].title)
			})
	}

	const propertyValues = useMemo(() => {
		return _.pick(data, Object.keys(propertyInitalValues)) as IProperty
		//eslint-disable-next-line
	}, [step])
	const propertyFinancialValues = useMemo(() => {
		return _.pick(data, Object.keys(propertyFinancialsInitialValues)) as IPropertyFinancials
		//eslint-disable-next-line
	}, [step])

	const stepsUI = [
		<AddProperty data={propertyValues} next={next} />,
		<AddFinancials data={propertyFinancialValues} next={next} prev={prev} />,
		<UploadMultipleImages data={_.pick(data, ['files'])} prev={prev} handleSubmit={handleSubmit} />,
	]

	return stepsUI[step]
}

export default PropertyForm
