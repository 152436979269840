import {FC} from 'react'
import {useDispatch} from 'react-redux'
import {KTSVG} from '_metronic/helpers'
import {setCurrentImage} from '../core/slice'

const UserKycImageHeader: FC<{title?: string}> = ({title}) => {
  const dispatch = useDispatch()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{title}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => {
          dispatch(setCurrentImage({images: null}))
        }}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {UserKycImageHeader}
