import {Navigate, Routes, Route} from 'react-router-dom'
import {PageLink, PageTitle} from '_metronic/layout/core'
import {AddRent} from './components/AddRent'

const rentBreadCrumbs: Array<PageLink> = [
  {
    title: 'Rent',
    path: '/rent',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const RentPage = () => {
  return (
    <Routes>
      <Route
        path='add'
        element={
          <>
            <PageTitle breadcrumbs={rentBreadCrumbs}>Add Rent</PageTitle>
            <AddRent />
          </>
        }
      />
      <Route index element={<Navigate to='add' />} />
    </Routes>
  )
}

export default RentPage
