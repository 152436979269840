import {createSlice} from '@reduxjs/toolkit'

interface IUser {
  id: number
  email: string
  firstName: string
  lastName: string
  kycImages: boolean
  active: boolean
  approved: boolean
  created_at: string | Date
  updated_at: string | Date
}

export interface IAuthState {
  authToken: string
  user: IUser | null
  isLoggedIn: boolean
}

// interface IAuthReducers {
//   setCurrentUser(state: IAuthState, {payload}: {payload: {data: IUser}}): void
//   saveAuthToken(state: IAuthState, {payload}: {payload: {token: string}}): void
//   setLogin(state: IAuthState, {payload}: {payload: boolean}): void
// }

// const initialState = {
//   authToken: '',
//   user: null,
//   isLoggedIn: false,
// }

const authSlice = createSlice({
  name: 'authSlice',
  initialState: {
    authToken: "",
    user: null,
    isLoggedIn: false,
  } as IAuthState,
  reducers: {
    setCurrentUser(state: IAuthState, {payload}: {payload: {data: IUser}}) {
      state.user = payload.data
    },
    saveAuthToken(state: IAuthState, {payload}: {payload: {token: string}}) {
      state.authToken = payload.token
    },
    setLogin(state: IAuthState, {payload}: {payload: boolean}) {
      state.isLoggedIn = payload
    },
  },
})

export const {saveAuthToken, setCurrentUser, setLogin} = authSlice.actions
export default authSlice.reducer
