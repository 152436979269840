/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useGetPropertyByIdQuery } from '../core/api'
import { EntriesList } from 'app/components/EntriesList'

const Description: React.FC = () => {
  const params = useParams()
  const propertyByIdResponse = useGetPropertyByIdQuery({ id: params.id! }, {
    selectFromResult: (result) => {
      const data = !!result.data?.data && !!result.data?.data?.Description ? result.data?.data?.Description : {}
      return {
        ...result,
        data
      }
    }
  })
  const navigate = useNavigate()

  useEffect(() => {
    if (!propertyByIdResponse.isFetching && Object.keys(propertyByIdResponse.data).length === 0) {
      navigate("add")
    }
    //eslint-disable-next-line
  }, [propertyByIdResponse.isFetching])

  return (
    <EntriesList
      title='Property Description'
      entries={propertyByIdResponse.data}
      renderButton={() => (
        <Link to='add' className='btn btn-primary align-self-center'>
          Edit
        </Link>
      )}
    />
  )
}
export { Description }
