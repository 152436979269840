import {api} from 'app/services'
import {IRent} from './_models'

const rentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addRent: builder.mutation({
      query: ({data, token}: {token: string; data: IRent}) => ({
        url: '/rent/create-rent',
        body: data,
        method: 'POST',
        headers: {
          'x-access-token': token,
        },
      }),
    }),
  }),
  overrideExisting: false,
})

export const {useAddRentMutation} = rentApi
