import { Navigate, Route, Routes } from 'react-router-dom'
import { UsersList } from './UsersList'
import UsersPageTitle from './UsersPageTitle'

const UsersPage = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <UsersPageTitle />
            <UsersList approved={false} />
          </>
        }
        path={"unapproved"}
      />
      <Route
        element={
          <>
            <UsersPageTitle />
            <UsersList approved={true} />
          </>
        }
        path={"approved"}
      />
      <Route index element={<Navigate to='approved' />} />
    </Routes>
  )
}
export default UsersPage
