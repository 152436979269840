import React from 'react'
import { IProperty } from '../core/_models'
import StandardForm from 'app/components/StandardForm'
import { addPropertySchema } from '../core/_schema'
import { propertyFields } from "../formFields/add-property"

interface Props {
  data: IProperty
  next: (values: IProperty) => void;
}

const AddProperty: React.FC<Props> = (props) => {
  const handleSubmit = (values: IProperty) => {
    let propertyValues: IProperty = {
      name: values.name,
      country: values.country,
      city: values.city,
      county: values.county,
      state: values.state,
      class: values.class,
      countryInitials: values.countryInitials,
      about: values.about,
      latitude: parseFloat(values.latitude.toString()),
      longitude: parseFloat(values.longitude.toString()),
      postCode: parseInt(values.postCode.toString()),
      units: parseInt(values.units),
      priceUsd: parseInt(values.priceUsd)
    }
    props.next(propertyValues)
  }
  return (
    <StandardForm
      getFields={propertyFields}
      title='Add New Property'
      submitTitle='Next'
      onSubmit={handleSubmit}
      initialValues={props.data}
      validationSchema={addPropertySchema}
    />
  )
}

export { AddProperty }
