/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useGetPropertyByIdQuery} from '../core/api'
import {EntriesList} from 'app/components/EntriesList'
import {Loader} from 'app/components/Loader'
const Details: React.FC = () => {
  const params = useParams()
  const propertyByIdResponse = useGetPropertyByIdQuery({id: params.id!})
  const [entries, setEntries] = useState({})
  useEffect(() => {
    if (propertyByIdResponse.isSuccess) {
      const data = propertyByIdResponse.data.data
      const validEntries = {
        name: data.name,
        class: data.class,
        city: data.city,
        state: data.state,
        country: data.country,
        countryInitials: data.countryInitials,
        county: data.county,
        postCode: data.postCode,
        units: data.Unit.unitsQuantity,
        priceUsd: data.Unit.priceUsd,
      }
      setEntries(validEntries)
    }
    // eslint-disable-next-line
  }, [propertyByIdResponse.isSuccess])

  if (propertyByIdResponse.isLoading || propertyByIdResponse.isFetching) {
    return <Loader />
  }

  return (
    <EntriesList
      title='Property Information'
      entries={entries}
      renderButton={() => (
        <Link to='edit' className='btn btn-primary align-self-center'>
          Edit
        </Link>
      )}
    />
  )
}
export {Details}
